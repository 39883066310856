import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-editor-quill',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","single-line":"","rows":"15"},model:{value:(_vm.new_value),callback:function ($$v) {_vm.new_value=$$v},expression:"new_value"}}),_c(VBtn,{attrs:{"text":"","block":"","color":"primary"},on:{"click":function($event){_vm.$refs.modal_message.open();this.getMessageTemplates();}}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("choose_from_message_templates"))+" ")]),_c('BaseDraggableModal',{ref:"modal_message",attrs:{"name":'modal_message',"max-height":"80vh","width":"70vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$store.getters.translate("messagetemplates"))+" "+_vm._s(_vm.$store.getters.translate(_vm.module_name)))]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.modal_message.close()}}},[_c(VIcon,[_vm._v("mdi-content-save")])],1)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),(!_vm.show_create)?_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.messagetemplates,"loading":_vm.loading,"selectable-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{domProps:{"innerHTML":_vm._s(item.message)},on:{"click":function($event){return _vm.setMessage(item.message)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteRecord(item.id)}}},[_vm._v("mdi-delete")])]}}],null,false,3648555926)})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-combobox
      :label="$store.getters.translate('tags')"
      v-model="new_value"
      :items="tags"
      outlined
      chips
      deletable-chips
      small-chips
      :multiple="!single"
      item-value="name.en"
      item-text="name.en"
      :return-object="false"
      append-icon="mdi-tag"
      ref="menu"
      @click:append="getTags();$refs.menu.blur();$refs[modal_name].open()"
      dense
      hide-details
      height="auto"
      clearable/>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="modal_name"
        tabindex="0"
        @blur.native="$refs[modal_name].close()">
      <v-card :loading="loading">
        <BaseModal>
          <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
            <v-toolbar-title>{{ getToolbarTitle }}</v-toolbar-title>
            <v-spacer/>
            <v-btn text small @click.stop="getTags();$refs[modal_name].close()">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="tags"
                :loading="loading"
                selectable-key="id">
                <template v-slot:item.updated_at="{ item }">{{
                  moment(item.updated_at).format("DD-MM-YYYY HH:mm")
                }}</template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteRecord(item.id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
          <v-footer slot="footer">
            <v-btn-toggle v-model="record.global">
              <v-btn x-small value="true">{{ $store.getters.translate("global") }}</v-btn>
              <v-btn x-small value="false">{{ $store.getters.translate("local") }}</v-btn>
            </v-btn-toggle>
            <v-text-field
                class="ml-5"
                append-outer-icon="mdi-plus"
                @click:append-outer="save"
                v-model="record.name"
                :label="$store.getters.translate('create')"
                single-line/>
          </v-footer>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["value", "module_name", "single"],
  data() {
    return {
      modal_name: `modal_tags_${this.module_name}`,
      new_value: [],
      tags: [],
      loading: false,
      record: {
        name: "",
        type: this.$models[this.module_name],
        global: "true",
      },
      headers: [
        {
          text: this.$store.getters.translate("name"),
          align: "start",
          sortable: true,
          value: "name.en",
        },
        { text: this.$store.getters.translate("updated"), value: "updated_at" },
        {
          text: this.$store.getters.translate("actions"),
          sortable: false,
          align: "right",
          value: "actions",
        },
      ],
    };
  },
  created() {
    this.new_value = this.value;
    this.getTags();
  },
  methods: {
    update() {
      this.$emit("input", this.new_value);
      this.$emit("change");
    },
    save() {
      if(!this.loading) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/tags", this.record)
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                if (this.module_name === "templates") {
                  this.new_value.push(this.record.name);
                  this.$emit("save");
                } else {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                }
                this.record.name = "";
                this.getTags();
                this.$refs[this.modal_name].close();
              } else {
                var errorMessage = response.data.message;
                var errors = Object.keys(response.data.message);
                this.$toasted.error([errorMessage[errors[0]]]);
              }
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
    },
    async getTags() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/tags?model=" + this.$models[this.module_name])
        .then((response) => {
          this.tags = response.data;
          this.$emit("tags", this.tags);
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    deleteRecord(id) {
      this.loading = true;
      this.removeTag(id);
      this.$http
        .delete(this.$store.getters.appUrl + "v2/tags/" + id)
        .then(() => {
          this.getTags();
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    removeTag(id) {
      if (this.module_name === "templates") {
        let remove_tag = null,
          cnt = this.new_value.length;
        for (let tag of this.tags) {
          if (tag.id === id) {
            remove_tag = tag.name.en;
            break;
          }
        }
        if (remove_tag) {
          this.new_value = this.new_value.filter((value) => value != remove_tag);
          if (this.new_value.length != cnt) {
            this.$emit("save");
          }
        }
      }
    },
  },
  computed: {
    getToolbarTitle() {
      return this.$store.getters.translate("edit_tags_for") + " " + this.$store.getters.translate(this.module_name);
    },
  },
  watch: {
    new_value: {
      handler() {
        this.update();
      },
    },
    value: {
      handler() {
        this.new_value = this.value;
      },
    },
    module_name: {
      handler() {
        this.getTags();
      },
    },
  },
};
</script>
<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view/>
    <modals-container/>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  created: function () {
    this.$vuetify.lang.current = this.$store.getters.getLanguage
    // this.$http.interceptors.response.use(undefined, function (err) {
    //   return new Promise(function (err) {
    //     if (err.status === 401) {
    //             console.log(err)
    //       this.$store.dispatch('logout')
    //       this.$router.push('/login')
    //     }
    //     throw err;
    //   });
    // });
  },
};
</script>

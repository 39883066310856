import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c(VFileInput,{attrs:{"label":_vm.$store.getters.translate('files'),"show-size":"","multiple":""},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),(_vm.files.length > 0)?[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Naam")]),_c('th',{staticClass:"text-left"},[_vm._v("Categorie")]),_c('th',{staticClass:"text-right"},[_vm._v("Verwijderen")])])]),_c('tbody',_vm._l((_vm.files),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_c(VSelect,{attrs:{"label":_vm.$store.getters.translate('category'),"items":['Uitslagbrief', 'TestVision login gegevens', 'Bewijs voor vrijstelling']},model:{value:(item.category),callback:function ($$v) {_vm.$set(item, "category", $$v)},expression:"item.category"}})],1),_c('td',{staticClass:"text-right"},[_c(VIcon,{on:{"click":function($event){return _vm.removeFile(item.name)}}},[_vm._v("mdi-trash-can")])],1)])}),0)]},proxy:true}],null,false,3147148069)}),_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":_vm.uploadFiles}},[_vm._v("Upload files")])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
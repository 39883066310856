<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        ref="input"/>
    </template>
    <v-date-picker
      ref="datepicker"
      color="primary"
      v-model="new_date"
      no-title
      scrollable/>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  props: ["value", "label", "year"],
  data() {
    return {
      menu: false,
      new_date: null,
    };
  },
  mounted() {
    this.new_date = this.value;
    this.$emit("input", this.new_date);
  },
  methods: {
    updateDate() {
      this.menu = false;
      this.$emit("input", this.new_date);
      this.$emit("change");
    },
    format(val) {
      return moment(val).format("DD-MM-YYYY");
    },
  },
  computed: {
    formattedDate() {
      if (this.value) {
        return moment(this.value).format("DD-MM-YYYY");
      }
      return null;
    },
  },
  watch: {
    new_date: {
      handler() {
        this.updateDate();
      },
    },
    menu(value) {
      if (this.year) {
        value &&
          this.$nextTick(() => (this.$refs.datepicker.activePicker = "YEAR"));
      }
    },
  },
};
</script>
<template>
  <div>
    <v-btn @click.stop="load(), $emit('click')" text small color="primary">
      {{ status ? $store.getters.translate(status) : "status" }}
    </v-btn>
    <BaseDraggableModal
        :name="'modal_statuses'"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        ref="modal_statuses">
      <v-card min-height="300">
        <BaseModal>
          <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
            <v-btn icon @click.stop="$refs.modal_statuses.close();">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
                $store.getters.translate("status")
              }}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text>
              <v-layout
                v-if="loading"
                align-center
                justify-center
                column
                fill-height
                class="mt-5">
                <v-flex row align-center>
                  <div class="justify-center" v-if="!blob">
                    <v-progress-circular :size="100" color="primary" indeterminate/>
                    <transition name="fade">
                      <h3 class="mt-5">{{ $store.getters.translate() }}</h3>
                    </transition>
                  </div>
                </v-flex>
              </v-layout>
              <v-timeline v-else class="mt-5" dense clipped>
                <v-timeline-item
                  class="mb-4"
                  dark
                  color="primary"
                  icon-color="grey lighten-2"
                  icon="mdi-check"
                  :large="true">
                  <v-row justify="space-between">
                    <v-col cols="7">
                      <h4>{{ $store.getters.translate("created") }}</h4>
                      <small>{{ created_by }}</small>
                    </v-col>
                    <v-col class="text-right" cols="5">
                      {{ moment(created_at).format("DD-MM-YYYY") }}
                    </v-col>
                  </v-row>
                </v-timeline-item>
                <template v-for="(status, index) in status_options">
                  <v-timeline-item
                    :key="index"
                    class="mb-4"
                    dark
                    :color="getColor(status)"
                    icon-color="grey lighten-2"
                    :icon="getIcon(status)"
                    small>
                    <v-row justify="space-between">
                      <v-col cols="7">
                        <h4>{{ $store.getters.translate(status) }}</h4>
                        <small>{{ getReason(status) }}</small>
                      </v-col>
                      <v-col class="text-right" cols="5">
                        {{ getCreatedAt(status) }}
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </template>
              </v-timeline>
            </v-card-text>
          </div>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import moment from "moment";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["model", "model_id", "status", "created_by", "created_at", "record"],
  data() {
    return {
      loading: false,
      blob: null,
      statuses: [],
      status_options: [],
    };
  },
  methods: {
    load() {
      this.loading = true;
      this.$refs.modal_statuses.open();
      let result = {
        model: this.$models[this.model] ? this.$models[this.model] : this.model,
        model_id: this.model_id,
      };
      this.$http
        .post(this.$store.getters.appUrl + "v2/status", result)
        .then((response) => {
          this.loading = false;
          this.statuses = this.$lodash.sortBy(response.data, "created_at");
          this.statuses = this.statuses.filter((status) => status.name !== "created");
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
      this.$http
        .get(this.$store.getters.appUrl + "v2/fields?model_type=" + this.$models[this.model] + "&field=status")
        .then((response) => {
          this.loading = false;
          this.status_options = response.data[0]
            ? response.data[0].available_options
            : [];
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    getColor(status_name) {
      let has_status = this.statuses.find((status) => status.name === status_name);
      if (has_status) {
        return "primary";
      }
      return "grey";
    },
    getStatus(status_name) {
      let has_status = this.statuses.find((status) => status.name === status_name);
      if (has_status) {
        return has_status;
      }
      return null;
    },
    getReason(status_name) {
      let has_status = this.statuses.find((status) => status.name === status_name);
      if (has_status) {
        return has_status.reason;
      }
      return null;
    },
    getCreatedAt(status_name) {
      let has_status = this.statuses.find((status) => status.name === status_name);
      if (has_status) {
        return moment(has_status.created_at).format("DD-MM-YYYY");
      }
      return null;
    },
    getIcon(status_name) {
      let has_status = this.statuses.find((status) => status.name === status_name);
      if (has_status) {
        return "mdi-check";
      }
      return null;
    },
  },
  computed: {
    getRegistered() {
      let has_status = this.statuses.find((status) => status.name === "has_registered");
      return !!has_status;
    },
  },
};
</script>
<template>
  <div>
    <v-row>
      <v-col>
        <v-file-input
          :label="$store.getters.translate('files')"
          show-size
          v-model="files"
          multiple/>
        <template v-if="files.length > 0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Naam</th>
                  <th class="text-left">Categorie</th>
                  <th class="text-right">Verwijderen</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in files" :key="index">
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    <v-select
                      :label="$store.getters.translate('category')"
                      v-model="item.category"
                      :items="['Uitslagbrief', 'TestVision login gegevens', 'Bewijs voor vrijstelling']">
                    </v-select>
                  </td>
                  <td class="text-right">
                    <v-icon @click="removeFile(item.name)">mdi-trash-can</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn block color="primary" @click="uploadFiles">Upload files</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["model", "model_id"],
  data() {
    return {
      files: [],
      uploaded_files: [],
    };
  },
  methods: {
    uploadFiles() {
      this.files.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file, file.name);
        let model = "Candidate";
        let model_id = 31;
        let category = file.category;
        this.$http
          .post(this.$store.getters.appUrl + "v2/files?model=" + model + "&model_id=" + model_id + "&category=" + category + "&name=" + file.name,
            formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            file.uploaded = true;
            this.$toasted.success(file.name + " " + this.$store.getters.translate("was_uploaded"));
            this.removeFile(file.name);
            if (this.files.length == 0) {
              this.$emit("refresh");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    removeFile(name) {
      this.files = this.files.filter((file) => file.name !== name);
    },
  },
};
</script>
export default
    {
        Dossier: [
            'Candidate',
            'Scheme',
        ],
        Certificate: [
            'Template',
            'File',
            'Candidate',
            'Dossier',
            'Exam',
            'Scheme'
        ],
        Exam: [
            'Location',
            'Candidate',
            'Session',
            'Person',
            'Scheme'
        ],
        Candidate: [
            'Person',
            'Exam',
            'ExamCandidateSession'
        ],
        Assessor: [
            'Person',
        ],
        Session: [
            'Exam',
            'Scheme'
        ]
    }

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtn,{attrs:{"small":""},on:{"click":function($event){_vm.$refs.modal_notification.open();_vm.showHandler();}}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("messages"))+" ")]),_c(VDivider,{attrs:{"vertical":""}}),_c('BaseDraggableModal',{ref:"modal_notification",attrs:{"name":'modal_notification',"max-height":"80vh","width":"70vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly}},[_c(VCard,[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.modal_notification.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$store.getters.translate("messages")))]),_c(VSpacer)],1),_c(VCardText,{attrs:{"slot":"content"},slot:"content"},[_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.notifications,"loading":_vm.loading_notifications,"items-per-page":5},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-2 mb-2"},[_c('p',{domProps:{"innerHTML":_vm._s(item.data.message)}}),_c(VChip,{attrs:{"x-small":"","label":""}},[_c(VIcon,{attrs:{"left":"","x-small":""}},[_vm._v("mdi-eye")]),_c('span',[_vm._v(_vm._s(item.read_at ? _vm.moment(item.read_at).format("DD-MM-YYYY") : _vm.$store.getters.translate("has_not_been_read")))])],1),_c(VChip,{staticClass:"ml-2",attrs:{"x-small":"","label":""}},[_c(VIcon,{attrs:{"left":"","x-small":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(item.created_at ? _vm.moment(item.created_at).format("DD-MM-YYYY") : "-"))])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"right":""},on:{"click":function($event){return _vm.deleteNotification(item.id)}}},[_vm._v(" mdi-close ")])]}}])}),(_vm.$store.getters.sms && _vm.phonenumber)?[_c(VSwitch,{attrs:{"label":_vm.$store.getters.translate('send_with_sms')},model:{value:(_vm.record.prefers_sms),callback:function ($$v) {_vm.$set(_vm.record, "prefers_sms", $$v)},expression:"record.prefers_sms"}}),(_vm.record.prefers_sms)?_c('small',[_vm._v(_vm._s(_vm.$store.getters.translate("sms_cost_hint")))]):_vm._e()]:_vm._e(),(_vm.record.prefers_sms)?_c(VTextarea,{attrs:{"outlined":"","label":_vm.$store.getters.translate('send_notification')},model:{value:(_vm.record.message),callback:function ($$v) {_vm.$set(_vm.record, "message", $$v)},expression:"record.message"}}):_c('base-message',{attrs:{"label":_vm.$store.getters.translate('send_notification')},model:{value:(_vm.record.message),callback:function ($$v) {_vm.$set(_vm.record, "message", $$v)},expression:"record.message"}})],2)],1)],1),_c(VCardActions,{attrs:{"slot":"footer"},slot:"footer"},[_c(VBtn,{attrs:{"loading":_vm.saving_template,"disabled":!_vm.record.message,"text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("save_template"))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.loading,"disabled":_vm.isDisabled,"color":"primary","text":""},on:{"click":_vm.sendNotification}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("send"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card class="pa-5" :loading="loading" :disabled="loading">
    <div>
      <div style="float: right;">
        <v-btn @click.stop="exportCalendar('pdf')" small color="primary" class="ma-1" style="width:200px;">
          {{ $store.getters.translate("export_to_pdf") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
        <br/>
        <v-btn @click.stop="exportCalendar('excel')" small color="primary" class="ma-1" style="width:200px;">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
      </div>
      <h2 class="text-center">{{ $store.getters.translate("unscheduled_events") }}</h2>
      <table style="border-spacing: 0px; -webkit-border-spacing: 0px; border-collapse: collapse; -webkit-border-collapse:collapse;" class="mt-5 mb-5 text-center">
        <thead><tr id="unscheduled_events_head"/></thead>
        <tbody id="unscheduled_events_body"/>
      </table>
    </div>
    <FullCalendar ref="full_calendar" :plugins="plugins" :options="calendarOptions" />
  </v-card>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    FullCalendar
  },
  props: ["loading", "exam_name", "examination_date", "sessions"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("planner"),
        name: "planner",
        model: "planner",
      },
      calendarOptions: {
        schedulerLicenseKey:'0428499722-fcs-1583088865',
        plugins: [ dayGridPlugin, interactionPlugin, resourceTimeGridPlugin ],
        headerToolbar: {
          start: '',
          center: 'title',
          end: ''
        },
        initialView: 'resourceTimeGridDay',
        allDaySlot: false,
        datesAboveResources: true,
        eventTextColor: "black",
        slotDuration: "00:05:00",
        slotMinTime: "08:00:00",
        slotMaxTime: "18:00:00",
        editable: true,
        eventOverlap: false,
        eventResizableFromStart: true,
        eventClick: this.handleEventClick,
        eventResize: this.handleEventDropResize,
        eventDrop: this.handleEventDropResize,
        drop: this.handleExternalEventDrop,
        eventReceive: this.handleEventReceive,
        resourceOrder: '+sorting',
        resources: [],
        events: [],
      },
      should_be_removed: false,
      first_launch: true
    }
  },
  methods: {
    handleEventClick(info) {
      this.$confirm(this.$store.getters.translate("delete_schedule")).then((res) => {
        if (res) {
          this.sendRequest(info.event._def.publicId, null, null);
          document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).innerHTML = info.event._def.title;
          document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).style.backgroundColor = helpFunctions.getColor(info.event._def.title);
          info.event.remove();
        }
      });
    },
    handleEventDropResize(info) {
      let from = moment.utc(info.event._instance.range.start).format("HH:mm:ss");
      let till = moment.utc(info.event._instance.range.end).format("HH:mm:ss");
      this.sendRequest(info.event._def.publicId, from, till);
    },
    handleExternalEventDrop(info) {
      let obj = JSON.parse(info.draggedEl.dataset.event);
      if(obj.resourceId === info.resource._resource.id) {
        this.should_be_removed = false;
      }
      else {
        this.should_be_removed = true;
      }
    },
    handleEventReceive(info) {
      if(this.should_be_removed) {
        this.$toasted.error(this.$store.getters.translate("incorrect_candidate"));
        info.event.remove();
        this.should_be_removed = false;
      }
      else {
        document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).innerHTML = '';
        document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).style.backgroundColor = "white";
        let from = moment.utc(info.event._instance.range.start).format("HH:mm:ss");
        let till = moment.utc(info.event._instance.range.end).format("HH:mm:ss");
        this.sendRequest(info.event._def.publicId, from, till);
      }
    },
    sendRequest(id, from, till) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-time", { id: id, from: from, till: till })
          .then(() => {
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    clearCalendar() {
      let removeEvents = this.$refs.full_calendar.getApi().getEvents();
      removeEvents.forEach(event => {
        event.remove();
      });
      this.$refs.full_calendar.getApi().refetchResources();
    },
    exportCalendar(export_type) {
      this.loading = true;
      let html = helpFunctions.exportCalendar(export_type, this.$refs.full_calendar.getApi().getResources());
      if(export_type === 'excel') {
        this.exportTableToExcel(html);
      }
      else {
        this.exportTableToPdf(html);
      }
    },
    exportTableToPdf(html) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-pdf", {html: html, file_name: this.exam_name + " (" + this.examination_date + ")"}, {responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.exam_name + " (" + this.examination_date + ").pdf";
            link.click();
            URL.revokeObjectURL(link.href);
            this.loading = false;
          });
    },
    exportTableToExcel(html) {
      const link = document.createElement("a");
      link.href = 'data: application/vnd.ms-excel, ' + html.replace(/ /g, '%20');
      link.download = this.exam_name + " (" + this.examination_date + ").xls";
      link.click();
      this.loading = false;
    },
  },
  watch: {
    sessions: {
      handler() {
        this.clearCalendar();
        if(this.examination_date) {
          var added_candidates = [];
          var table_data = [];
          var table_header = "<th style='border:1px solid grey;' class='pa-2'></th>";
          this.sessions.forEach((session) => {
            let title = helpFunctions.getTitle(session.name);
            let color = helpFunctions.getColor(title);
            let duration = helpFunctions.getDuration(session.scheme_session);
            if(session.room) {
              title += ' (' + session.room.name + ')';
            }
            session.exam_candidate_sessions.forEach((exam_candidate) => {
              if (!added_candidates.includes(exam_candidate.candidate.id)) {
                table_data[exam_candidate.candidate.id] = [];
                added_candidates.push(exam_candidate.candidate.id);
                this.$refs.full_calendar.getApi().addResource({"id": exam_candidate.candidate.id, "title": exam_candidate.candidate.name, "sorting": added_candidates.length});
                table_header += "<th style='border:1px solid grey;' class='pa-2'>" + exam_candidate.candidate.name + "</th>";
              }
              if(exam_candidate.status === "exempt") {
                table_data[exam_candidate.candidate.id][session.name] = '<td style="border:1px solid grey; background-color: gray;" class="pa-2"></td>';
              }
              else if(exam_candidate.from && exam_candidate.till) {
                this.$refs.full_calendar.getApi().addEvent(helpFunctions.getPlannerEvent(exam_candidate.id, exam_candidate.candidate.id, this.examination_date, exam_candidate.from, exam_candidate.till, title, color));
                table_data[exam_candidate.candidate.id][session.name] = helpFunctions.getEmptyPlannerTableRow(exam_candidate.id, exam_candidate.candidate.id, duration, title, color);
              }
              else {
                table_data[exam_candidate.candidate.id][session.name] = helpFunctions.getFilledPlannerTableRow(exam_candidate.id, exam_candidate.candidate.id, duration, title, color);
              }
            });
          });
          var table_body = "";
          this.sessions.forEach((session) => {
            table_body += "<tr>";
            table_body += "<td style='border:1px solid grey;' class='pa-2'>" + session.name + "</td>";
            added_candidates.forEach((candidate_id) => {
              if(table_data[candidate_id][session.name]) {
                table_body += table_data[candidate_id][session.name];
              }
              else {
                table_body += '<td style="border:1px solid grey; background-color: gray;" class="pa-2"></td>';
              }
            });
            table_body += "</tr>";
          });
          this.$refs.full_calendar.getApi().gotoDate(moment(this.examination_date, "DD-MM-YYYY").toDate());
          document.getElementById("unscheduled_events_head").innerHTML = table_header;
          document.getElementById("unscheduled_events_body").innerHTML = table_body;

          if(this.first_launch) {
            new Draggable(document.getElementById("unscheduled_events_body"), {
              itemSelector: '.draggable-class'
            });
            this.first_launch = false;
          }
        }
      },
    },
  },
}
</script>

<style>
.calendar {
  background-color: #FFFFFF;
}
</style>
<template>
  <v-card class="pa-5" :loading="loading" :disabled="loading">
    <div>
      <div style="float: right;">
        <v-btn @click.stop="exportCalendar('pdf')" small color="primary" class="ma-1" style="width:200px;">
          {{ $store.getters.translate("export_to_pdf") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
        <br/>
        <v-btn @click.stop="exportCalendar('excel')" small color="primary" class="ma-1" style="width:200px;">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
      </div>
      <h2 class="text-center">{{ $store.getters.translate("unscheduled_events") }}</h2>
      <table style="border-spacing: 0px; -webkit-border-spacing: 0px; border-collapse: collapse; -webkit-border-collapse:collapse;" class="mt-5 mb-5 text-center">
        <thead><tr id="unscheduled_events_head"/></thead>
        <tbody id="unscheduled_events_body"/>
      </table>
    </div>
    <FullCalendar ref="full_calendar" :plugins="plugins" :options="calendarOptions" />
  </v-card>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    FullCalendar
  },
  props: ["loading", "record"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("planner"),
        name: "planner",
        model: "planner",
      },
      calendarOptions: {
        schedulerLicenseKey:'0428499722-fcs-1583088865',
        plugins: [ dayGridPlugin, interactionPlugin, resourceTimeGridPlugin ],
        headerToolbar: false,
        initialView: 'resourceTimeGridDay',
        allDaySlot: false,
        datesAboveResources: true,
        eventTextColor: "black",
        slotDuration: "00:05:00",
        slotMinTime: "08:00:00",
        slotMaxTime: "18:00:00",
        editable: true,
        eventOverlap: false,
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDropResize,
        drop: this.handleExternalEventDrop,
        eventReceive: this.handleEventReceive,
        resourceOrder: '+sorting',
        resources: [],
        events: [],
      },
      should_be_removed: false,
      first_launch: true
    }
  },
  methods: {
    handleEventClick(info) {
      this.$confirm(this.$store.getters.translate("delete_schedule")).then((res) => {
        if (res) {
          this.sendRequest(info.event._def.publicId, info.event._def.resourceIds[0], "");
          document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).innerHTML = info.event._def.title;
          document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).style.backgroundColor = helpFunctions.getColor(info.event._def.title);
          info.event.remove();
        }
      });
    },
    handleEventDropResize(info) {
      this.sendRequest(info.event._def.publicId, info.event._def.resourceIds[0], moment.utc(info.event._instance.range.start).format("HH:mm:ss"));
    },
    handleExternalEventDrop(info) {
      let obj = JSON.parse(info.draggedEl.dataset.event);
      if(obj.resourceId === info.resource._resource.id) {
        this.should_be_removed = false;
      }
      else {
        this.should_be_removed = true;
      }
    },
    handleEventReceive(info) {
      if(this.should_be_removed) {
        this.$toasted.error(this.$store.getters.translate("incorrect_candidate"));
        info.event.remove();
        this.should_be_removed = false;
      }
      else {
        document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).innerHTML = '';
        document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).style.backgroundColor = "white";
        this.sendRequest(info.event._def.publicId, info.event._def.resourceIds[0], moment.utc(info.event._instance.range.start).format("HH:mm:ss"));
      }
    },
    sendRequest(id, index, new_value) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-start-time", { id: id, index: index, new_value: new_value })
          .then(() => {
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    clearCalendar() {
      let removeEvents = this.$refs.full_calendar.getApi().getEvents();
      removeEvents.forEach(event => {
        event.remove();
      });
      this.$refs.full_calendar.getApi().refetchResources();
    },
    exportCalendar(export_type) {
      this.loading = true;
      let html = helpFunctions.exportCalendar(export_type, this.$refs.full_calendar.getApi().getResources());
      if(export_type === 'excel') {
        this.exportTableToExcel(html);
      }
      else {
        this.exportTableToPdf(html);
      }
    },
    exportTableToPdf(html) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-pdf", {html: html, file_name: this.record.name}, {responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.record.name + ".pdf";
            link.click();
            URL.revokeObjectURL(link.href);
            this.loading = false;
          });
    },
    exportTableToExcel(html) {
      const link = document.createElement("a");
      link.href = 'data: application/vnd.ms-excel, ' + html.replace(/ /g, '%20');
      link.download = this.record.name + ".xls";
      link.click();
      this.loading = false;
    },
  },
  watch: {
    record: {
      handler() {
        this.clearCalendar();
        var table_header = "";
        var table_body = "";
        var global_max_candidates = 0;
        this.record.scheme_sessions.forEach((scheme_session) => {
          table_body += "<tr>";
          let title = helpFunctions.getTitle(scheme_session.name);
          let color = helpFunctions.getColor(title);
          let duration = helpFunctions.getDuration(scheme_session);
          let duration_hours = moment(duration, "HH:mm").format("HH");
          let duration_minutes = moment(duration, "HH:mm").format("mm");
          var session_max_candidates = 6;
          if(scheme_session.max_candidates) {
            session_max_candidates = scheme_session.max_candidates;
          }
          if(session_max_candidates > global_max_candidates) {
            global_max_candidates = session_max_candidates;
          }
          if(scheme_session.start_times) {
            for (let i = 0; i < session_max_candidates; i++) {
              if(scheme_session.start_times[i] && scheme_session.start_times[i] !== "") {
                let today = moment(new Date()).format("DD-MM-YYYY");
                let start_time = moment(scheme_session.start_times[i], "HH:mm:ss").format("HH:mm");
                let end_time = moment(scheme_session.start_times[i], "HH:mm:ss").add({ hour: duration_hours, minute: duration_minutes }).format("HH:mm");
                this.$refs.full_calendar.getApi().addEvent(helpFunctions.getPlannerEvent(scheme_session.id, i, today, start_time, end_time, title, color));
                table_body += helpFunctions.getEmptyPlannerTableRow(scheme_session.id, i, duration, title, color);
              }
              else {
                table_body += helpFunctions.getFilledPlannerTableRow(scheme_session.id, i, duration, title, color);
              }
            }
          }
          else {
            for (let i = 0; i < session_max_candidates; i++) {
              table_body += helpFunctions.getFilledPlannerTableRow(scheme_session.id, i, duration, title, color);
            }
          }
          table_body += "</tr>";
        });

        for (let i = 0; i < global_max_candidates; i++) {
          table_header += "<th style='border:1px solid grey;' class='pa-2'>" + "Candidate " + (i+1) + "</th>";
          this.$refs.full_calendar.getApi().addResource({"id": i, "title": "Candidate " + (i+1), "sorting": i});
        }

        document.getElementById("unscheduled_events_head").innerHTML = table_header;
        document.getElementById("unscheduled_events_body").innerHTML = table_body;

        if(this.first_launch) {
          new Draggable(document.getElementById("unscheduled_events_body"), {
            itemSelector: '.draggable-class'
          });
          this.first_launch = false;
        }
      },
    },
  },
}
</script>

<style>
.fc-day-today {
  background-color: #FFFFFF !important;
}
</style>
<template>
  <div>
    <v-row>
      <v-col>
        <results @refresh="$emit('refresh')" :dossier="record_result"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>{{ $store.getters.translate('id') }}</th>
              <th>{{ $store.getters.translate('name') }}</th>
              <th>{{ $store.getters.translate('examination_date') }}</th>
              <th>{{ $store.getters.translate('location_name') }}</th>
              <th>{{ $store.getters.translate('room_name') }}</th>
              <th>{{ $store.getters.translate('reference') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><a @click="editExam(record.exams[index])">{{ record.exams[index].id }}</a></td>
              <td><a @click="editExam(record.exams[index])">{{ record.exams[index].name }}</a></td>
              <td>{{ record.exams[index].examination_date }}</td>
              <td>{{ record.exams[index].location_name }}</td>
              <td>{{ record.exams[index].room_name }}</td>
              <td>{{ record.exams[index].reference }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <template v-for="(certificate, key2) in record.certificates">
      <v-row v-if="certificate.exam_id === record.exams[index].id" :key="key2">
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th>{{ $store.getters.translate('certificate_number') }}</th>
                <th>{{ $store.getters.translate('name') }}</th>
                <th>{{ $store.getters.translate('candidate_name') }}</th>
                <th>{{ $store.getters.translate('status') }}</th>
                <th>{{ $store.getters.translate('issueing_date') }}</th>
                <th>{{ $store.getters.translate('valid_until') }}</th>
                <th>{{ $store.getters.translate('created_at') }}</th>
                <th style="text-align: center">{{ $store.getters.translate('visible_contact') }}</th>
                <th>{{ $store.getters.translate('actions') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><a @click="$emit('editCertificate', certificate)">{{ certificate.certificate_number }}</a></td>
                <td><a @click="$emit('editCertificate', certificate)">{{ certificate.name }}</a></td>
                <td><a @click="editCandidate(record.candidate_id)">{{ record.candidate_name }}</a></td>
                <td>{{ $store.getters.translate(certificate.status) }}</td>
                <td>{{ certificate.issueing_date }}</td>
                <td>{{ certificate.valid_until }}</td>
                <td>{{ moment(certificate.created_at, "YYYY-MM-DD").format("DD-MM-YYYY") }}</td>
                <td style="text-align: center" v-if="certificate.visible_contact === true">1</td>
                <td style="text-align: center" v-else>0</td>
                <td>
                  <v-icon v-if="canEdit" @click="$emit('editCertificate', certificate)" small class="mr-2">mdi-pencil</v-icon>
                  <v-icon v-if="canEdit" @click="deleteCertificate(certificate)" small color="error">mdi-trash-can</v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Results from "../../views/dossiers/includables/Results";

export default {
  components: {
    Results,
  },
  props: [
    "record_result",
    "record",
    "index"
  ],
  methods: {
    editCandidate(id) {
      this.$router.push("/candidates/" + id);
    },
    editExam(item) {
      this.$router.push("/exams/" + item.id);
    },
    deleteCertificate(item) {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          return this.$http
              .delete(this.$store.getters.appUrl + "v2/certificates/" + item.id)
              .then(() => {
                this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
                this.$emit('refresh');
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
              });
        }
      });
    },
    canEdit() {
      return this.$can("edit", "certificate");
    },
  },
};
</script>

<template>
  <BaseDraggableModal
      :name="modal_name"
      :ref="modal_name"
      :pdfSource="pdfSource"
      max-height="80vh"
      width="70vw"
      draggable="false"
      margin="auto">
    <v-card height="100%">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" slot="header" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.translate('toolbar') }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <div slot="content">
          <div class="preview-container" style="margin: 0 auto">
            <div>
              <object :data="pdfSource + '#zoom=100'" type="application/pdf" width="100%" height="800vh">
              </object>
            </div>
          </div>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["modal_name", "pdfSource"],
  components: {BaseModal, BaseDraggableModal},
  methods: {
    ...helpFunctions.modal_functions,
    reset() {
      this.closeModal(this.modal_name);
    },
  },
};
</script>
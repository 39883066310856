<template>
  <v-card :disabled="loading" :loading="loading">
    <v-tabs
      v-if="record"
      v-model="tab"
      :centered="false"
      :grow="false"
      :vertical="false"
      :right="false"
      :icons-and-text="false">
      <v-tabs-slider/>
      <v-tab>{{ $store.getters.translate("general_info") }}</v-tab>
      <v-tab v-if="model === 'company' || model === 'workflow' || (record.custom_fields && record.custom_fields.length > 0)">
        {{ $store.getters.translate("additional_information") }}
      </v-tab>
      <v-toolbar dense flat>
        <v-spacer/>
        <v-btn v-if="model === 'company'" :disabled="loading" :loading="loading" class="ma-3" small @click="$emit('export','pdf')" color="primary">
          {{ $store.getters.translate("export_to_pdf") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
        <v-btn v-if="model === 'company'" :disabled="loading" :loading="loading" class="ma-5" small @click="$emit('export','excel')" color="primary">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
        <v-btn v-if="canDelete" @click="$emit('archive')" text small color="secondary">
          <v-icon color="error">mdi-archive</v-icon>
        </v-btn>
        <v-btn v-if="canEdit" text small @click="$emit('change')" color="primary">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-toolbar>
      <v-tab-item>
        <v-card style="margin: 25px" flat tile>
          <v-card-text>
            <v-row>
              <v-col>
                <slot name="main_fields"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card style="margin: 25px" flat tile>
          <v-card-text>
            <v-row>
              <v-col>
                <slot name="module_fields"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-footer-panel ref="footer_panel" :model="module_name"/>
  </v-card>
</template>

<script>
export default {
  props: ["record", "module_name", "loading", "model"],
  data() {
    return {
      tab: 0,
    };
  },
  methods: {
    canEdit() {
      return this.$can("edit", this.model);
    },
    canDelete() {
      return this.$can("delete", this.model);
    },
  },
  watch: {
    record: {
      handler() {
        this.$refs.footer_panel.record = this.record;
        this.$refs.footer_panel.load();
      },
    },
  },
};
</script>
<style scoped>
.label-custom {
  border: 0px !important;
  font-style: italic;
}
</style>
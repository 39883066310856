import store from '../store'
export default {
    email: [
        value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || store.getters.translate('invalid_email')
        },
    ],
    required: [
        value => !!value || store.getters.translate('mandatory'),
    ],
    required_email: [
        value => !!value || store.getters.translate('mandatory'),
        value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || store.getters.translate('invalid_email')
        },
    ],
    requiredMax20: [
        value => !!value || store.getters.translate('mandatory'),
        value => (value || '').length <= 20 || 'Max 20 characters',
    ],
    min10: [
        value => (value || '').length >= 10 || 'Min 10 characters',
    ],
    phone: [
        value => {
            const pattern = /^(?=.*\d)[\d ]+$/
            return pattern.test(value) || store.getters.translate('invalid_phone')
        },
    ],
    dateFormat: [
        value => {
            const pattern = /^\d{2}([./-])\d{2}\1\d{4}$/
            return pattern.test(value) || store.getters.translate('date_format_must_be')
        },
    ],
    dateFormatRequired: [
        value => !!value || store.getters.translate('mandatory'),
        value => {
            const pattern = /^\d{2}([./-])\d{2}\1\d{4}$/
            return pattern.test(value) || store.getters.translate('date_format_must_be')
        },
    ],
    numeric: [
        value => {
            const pattern = /^(?=.*\d)[\d ]+$/
            return pattern.test(value) || store.getters.translate('only_numeric')
        },
    ],
    phoneRequired: [
        value => !!value || store.getters.translate('mandatory'),
        value => {
            const pattern = /^(?=.*\d)[\d ]+$/
            return pattern.test(value) || store.getters.translate('invalid_phone')
        },]
}
<template>
  <draggable
      :name="name"
      :draggable="!fullscreen ? '.draggable_selector' : false"
      :resizable="true"
      :adaptive="true"
      :cancelable="true"
      height="auto"
      :width="calcWidth"
      :classes="['content-wrapper', ...classes, ... fullscreen ? ['left-0'] : []]"
      overlayTransition="dialog-bottom-transition">
    <div :style="[{
          ...width ? { 'width' : width } : {},
          ...height ? { 'height' : height } : {},
          ...maxHeight ? { 'max-height' : maxHeight } : {},
          ...maxWidth ? { 'max-width' : '100%' } : {},
          ...fullscreen ? { 'width' : '100vw', 'max-width' : 'unset', 'height' : '100vh', 'max-height' : 'unset' } : {},
           ...margin ? { 'margin' : margin } : {},
        }]">
      <slot/>
    </div>
  </draggable>
</template>

<script>
export default {
  props: ["name", "width", "height", "maxWidth", "maxHeight", "fullscreen", "classes", "margin"],
  data() {
    return {
      calcWidth: 100,
    };
  },
  methods: {
    open() {
      this.$modal.show(this.name);
    },
    close() {
      this.$modal.hide(this.name);
    },
    calculateWidth() {
      if(this.fullscreen) {
        return this.calcWidth = window.innerWidth + 'px';
      }
      if(typeof this.width === 'string') {
        if(this.width.includes('vw') || this.width.includes('%')) {
          return this.calcWidth = parseInt(this.width) * window.innerWidth / 100 + 'px';
        }
      }
      this.calcWidth = this.width;
    },
  },
  beforeMount() {
    this.calculateWidth();
  },
  mounted() {
    window.addEventListener('resize', this.calculateWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateWidth);
  }
};
</script>

<style>
.content-wrapper > div > div {
  height: inherit;
  max-height: inherit;
}

.v--modal-overlay { z-index: 200 !important; }

.left-0 {
  left: 0!important;
}
</style>
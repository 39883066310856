import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from './router';
import VuexPersistence from 'vuex-persist';
Vue.use(Vuex);

let url = window.location.origin;

if (process.env.NODE_ENV == 'development') {
    url = 'http://localhost:8000';
}

export default new Vuex.Store({
    state: {
        Sidebar_drawer: true,
        right_sidebar: false,
        SidebarColor: 'white',
        SidebarBg: 'white',
        status: '',
        token: localStorage.getItem('token') || '',
        user: {},
        language: 'nl',
        app_url: url + '/api/',
        translations: [],
        notifications: [],
        metabase_dashboard: null,
        release_version: null,
        sms: false,
        lineheight: 3,
        colors: {
            primary_color: "#58595B",
            secondary_color: "#58595B",
        },
        panels: {},
        profile_name: null,
        profile_image: null,
    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload;
        },
        setRightSidebar(state, payload) {
            state.right_sidebar = payload;
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload;
        },
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state, token) {
            state.status = 'success';
            state.token = token;
        },
        auth_error(state) {
            state.status = 'error';
        },
        user(state, user) {
            state.user = user;
            state.profile_name = user.person ? user.person.casual_name : user.email;
            state.profile_image = user.person && user.person.file ? user.person.file.full_path : null;
        },
        logout(state) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
            axios.post(url + '/api/v2/logout').then(response => {
                console.log(response.data);
            })
            state.status = '';
            state.token = '';
            state.user = {};
            state.notifications = [];
            state.profile_image = null;
            state.profile_name = null;
        },
        setLanguage(state, payload) {
            state.language = payload;
        },
        setNotifyIsOn(state, payload) {
            state.notifyIsOn = payload;
        },
        setPanel(state, payload) {
            state.panels[payload.module_name] = payload.value;
        },
        refreshTranslations(state) {
            axios.get(url + '/api/v2/translations').then(response => {
                state.translations = response.data;
            });
        },
        setNotifications(state, payload) {
            state.notifications = payload;
        },
        setPermission(state, payload) {
            state.user.assign_permission = payload;
        },
        setColors(state, payload) {
            state.colors = payload;
        },
        setProfileName(state, payload) {
            state.profile_name = payload;
        },
        setProfileImage(state, payload) {
            state.profile_image = payload;
        },
        setMetabaseDashboard(state, payload) {
            state.metabase_dashboard = payload;
        },
        setReleaseVersion(state, payload) {
            state.release_version = payload;
        },
        setSMS(state, payload) {
            state.sms = payload;
        },
        setLineheight(state, payload) {
            state.lineheight = payload;
        }
    },
    actions: {
        login({ commit }, auth) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({ url: url + '/api/v2/login', data: auth, method: 'POST' }).then(response => {
                    const token = response.data.token;
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    commit('auth_success', token);
                    axios.get(url + '/api/user').then(response => {
                        commit('user', response.data);
                        if (response.data.is_candidate || response.data.is_assessor || response.data.is_contact) {
                            router.push('/portal');
                        }
                        else {
                            if(localStorage.getItem('path')) {
                                router.push(localStorage.getItem('path'));
                            }
                            else {
                                router.push('/');
                            }
                        }
                        resolve(response);
                    })
                }).catch(err => {
                    commit('auth_error');
                    localStorage.removeItem('token');
                    reject(err);
                });
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('logout');
                router.push('/login');
                resolve();
            })
        },
        setSidebar({commit}, data) {
            commit('SET_SIDEBAR_DRAWER', data);
        },
        setLanguage({commit}, data) {
            commit('setLanguage', data);
        },
        setNotifyIsOn({commit}, data) {
            commit('setNotifyIsOn', data);
        },
        refreshTranslations({commit}, data) {
            commit('refreshTranslations', data);
        },
        setPanel({commit}, data) {
            commit('setPanel', data);
        },
        setNotifications({commit}, data) {
            commit('setNotifications', data);
        },
        setPermission({commit}, data) {
            commit('setPermission', data);
        },
        setColors({commit}, data) {
            commit('setColors', data);
        },
        setProfileName({commit}, data) {
            commit('setProfileName', data);
        },
        setProfileImage({commit}, data) {
            commit('setProfileImage', data);
        },
        setRightSidebar({commit}, data) {
            commit('setRightSidebar', data);
        },
        setMetabaseDashboard({commit}, data) {
            commit('setMetabaseDashboard', data);
        },
        setReleaseVersion({commit}, data) {
            commit('setReleaseVersion', data);
        },
        setSMS({commit}, data) {
            commit('setSMS', data);
        },
        setLineheight({commit}, data) {
            commit('setLineheight', data);
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        token: state => state.token,
        authStatus: state => state.status,
        appUrl: state => state.app_url,
        getUserName: state => state.user.name,
        getUserEmail: state => state.user.email,
        getNotifications: state => state.notifications,
        getUserType: state => state.user.type,
        getUser: state => state.user,
        getLanguage: state => state.language,
        getNotifyIsOn: state => state.notifyIsOn,
        SidebarDrawer: state => state.Sidebar_drawer,
        rightSidebarDrawer: state => state.right_sidebar,
        translate: (state) => (payload) => {
            if (state.translations.length > 0) {
                let result = state.translations.filter(translation => translation.key === payload && translation.language === state.language);
                if (result.length > 0) {
                    return result[0].value;
                }
                return payload;
            } else {
                return payload;
            }
        },
        getPanel: (state) => (payload) => {
            return state.panels[payload];
        },
        primaryColor: state => state.colors.primary_color,
        secondaryColor: state => state.colors.secondary_color,
        profileImage: state => state.profile_image,
        profileName: state => state.profile_name,
        getMetabaseDashboard: state => state.metabase_dashboard,
        releaseVersion: state => state.release_version,
        sms: state => state.sms,
        lineheight: state => state.lineheight
    },
    plugins: [new VuexPersistence().plugin]
})
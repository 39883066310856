import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","text":"","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.go()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-import")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("import")))])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        ref="input"/>
    </template>
    <v-time-picker v-model="new_date" no-title/>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  props: ["value", "label"],
  data() {
    return {
      menu: false,
      new_date: this.value,
    };
  },
  methods: {
    updateDate() {
      this.menu = false;
      this.$emit("input", this.new_date);
      this.$emit("change");
    },
    format(val) {
      return moment(val).format("HH:MM");
    },
  },
  computed: {
    formattedDate() {
      if (this.new_date) {
        return moment(this.new_date).format("HH:MM");
      }
      return null
    },
  },
  watch: {
    new_date: {
      handler() {
        this.updateDate();
      },
    },
  },
};
</script>
<template>
  <div>
    <v-btn small @click="$refs.modal_create_user.open(); showHandler();">
      {{ $store.getters.translate("send_login_credentials") }}
    </v-btn>
    <v-divider vertical/>
    <BaseDraggableModal
        ref="modal_create_user"
        :name="'modal_create_user'"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card :disabled="loading" :loading="loading">
        <BaseModal>
          <v-card-title class="draggable_selector" slot="header">
            {{ $store.getters.translate("send_login_credentials") }}
          </v-card-title>
          <v-card-text slot="content">
              <v-checkbox
                v-if="$store.getters.sms"
                v-model="record.prefers_sms"
                :label="$store.getters.translate('send_with_sms')"/>
              <v-text-field
                v-if="record.prefers_sms"
                :label="$store.getters.translate('phonenumber')"
                v-model="record.phonenumber"
                type="tel"
                placeholder="+31612345678"/>
              <v-text-field
                v-else
                :disabled="loading"
                :label="$store.getters.translate('email')"
                v-model="record.email"
                counter
                required
                validate-on-blur
                :rules="emailRules"/>
              <base-message
                v-if="!record.prefers_sms"
                v-model="record.message"
                :label="$store.getters.translate('message')"/>
            </v-card-text>
          <div slot="footer">
            <v-divider/>
            <v-card-actions>
              <v-spacer/>
              <v-btn :disabled="isDisabled" color="primary" text @click="save">
                {{ $store.getters.translate("send") }}
              </v-btn>
            </v-card-actions>
          </div>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["person_id", "user_type", "emailaddress", "person"],
  data() {
    return {
      loading: false,
      emailRules: [
        (v) => !!v || this.$store.getters.translate("please_submit_valid_email"),
        (v) => /.+@.+\..+/.test(v) || this.$store.getters.translate("please_submit_valid_email"),
        (v) => this.check(v) || this.$store.getters.translate("please_submit_valid_email"),
      ],
      record: {
        email: this.emailaddress,
        phonenumber: null,
        person_id: this.person_id,
        user_type: this.user_type,
        message: null,
        prefers_sms: false,
      },
    };
  },
  methods: {
    save() {
      if(!this.loading) {
        this.loading = true;
        this.record.person_id = this.person_id;
        this.$http
            .post(this.$store.getters.appUrl + "v2/user/create", this.record)
            .then(() => {
              this.$toasted.success(this.$store.getters.translate("invitation_sent"));
              this.$emit("refresh");
              this.$refs.modal_create_user.close();
              this.showHandler();
              this.loading = false;
            })
            .catch((error) => {
              if (error.response.status == 422 && this.$store.getters.isLoggedIn) {
                this.$toasted.error(this.$store.getters.translate("email_taken_or_incorrect"));
              }
              this.loading = false;
            });
      }
    },
    check(value) {
      let validator = require("email-validator");
      return validator.validate(value);
    },
    showHandler() {
      this.record = {
        email: this.emailaddress,
        person_id: this.person_id,
        user_type: this.user_type,
        phonenumber: this.person.phonenumber,
      };
    }
  },
  computed: {
    isDisabled() {
      if((this.record.email || this.record.phonenumber) && this.record.person_id && this.record.user_type) {
        return false;
      }
      return true;
    },
  },
};
</script>
<template>
  <span>{{ date }}</span>
</template>

<script>
import moment from "moment";
export default {
  props: ["value"],
  computed: {
    date() {
      if (this.value) {
        return moment(this.value).format("DD-MM-YYYY");
      } else {
        return "-";
      }
    },
  },
};
</script>
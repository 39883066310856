import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuebar from 'vuebar'
import Axios from 'axios'
import VModal from 'vue-js-modal'
// import { cacheAdapterEnhancer } from 'axios-extensions';
import './plugins/base'
import VueSkycons from 'vue-skycons';
import vuetify from './plugins/vuetify'
Vue.use(VueSkycons, {
    color: '#01355F'
});
import Vuex from 'vuex'
Vue.use(Vuex)

import moment from 'moment'
moment.locale('nl');
Vue.prototype.moment = moment

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash })

Vue.prototype.$lodash = lodash;

import Footer from './components/commonComponents/BaseFooter'
Vue.component('v-footer-panel', Footer);

import Datepicker from './components/commonComponents/BaseDatepicker'
Vue.component('v-datepicker', Datepicker);

import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker)

import EmailAddresses from './components/commonComponents/BaseEmailAddresses'
Vue.component('email-addresses', EmailAddresses);

import BaseStatuses from './components/commonComponents/BaseStatuses'
Vue.component('base-statuses', BaseStatuses);

import BaseRow from './components/commonComponents/BaseRow'
Vue.component('base-row', BaseRow);

import BaseField from './components/commonComponents/BaseField'
Vue.component('base-field', BaseField);

import BaseDate from './components/commonComponents/BaseDate'
Vue.component('base-date', BaseDate);

import BaseNotification from './components/commonComponents/BaseNotification'
Vue.component('base-notification', BaseNotification);

import BaseImport from './components/commonComponents/BaseImport'
Vue.component('base-import', BaseImport);

import BaseTags from './components/commonComponents/BaseTags'
Vue.component('base-tags', BaseTags);

import BaseCreateUser from './components/commonComponents/BaseCreateUser'
Vue.component('base-create-user', BaseCreateUser);

import BaseMessage from './components/commonComponents/BaseMessage'
Vue.component('base-message', BaseMessage);

import BaseDocumentation from './components/commonComponents/BaseDocumentation'
Vue.component('base-documentation', BaseDocumentation);

import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.use(VuetifyGoogleAutocomplete, {
    apiKey: 'AIzaSyDETzSbmipza8r7Ux2aqq5jNYwt7gndx_4',
    language: 'nl',
});

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_LARAVEL_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2
});

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

Vue.use(vueDebounce,
    {
        lock: false,
        listenTo: 'keyup',
        defaultTime: '300ms',
        fireOnEmpty: false
    })

import rules from './plugins/rules'
Vue.prototype.$rules = rules;

import icons from './plugins/icons'
Vue.prototype.$icons = icons;

import models from './plugins/models'
Vue.prototype.$models = models;

import relations from './plugins/relations'
Vue.prototype.$relations = relations;

import related_models from './related_models'
Vue.prototype.$related_models = related_models;

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

Vue.config.productionTip = false
Vue.use(Vuebar);

Vue.prototype.$http = Axios;
const accessToken = 'Bearer ' + store.getters.token

if (accessToken) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = accessToken
}

Vue.prototype.$http.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 401 && store.getters.isLoggedIn) {
        store.dispatch('logout')
    }

    return Promise.reject(error)
})


import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';
// import abilities from './permissions';
// import defineRulesFor from './ability';
// let abilityRule = defineRulesFor(lodash.map(abilities, 'name'))

import { Can } from '@casl/vue';

Vue.prototype.$http.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 403 && store.getters.isLoggedIn) {
        //router.push('/403')
    }

    return Promise.reject(error)
})

Vue.prototype.$http.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 401 && store.getters.isLoggedIn) {
        router.push('/login')
    }

    return Promise.reject(error)
})


import Tawk from './Tawk'
Vue.use(Tawk)

import Toasted from 'vue-toasted';

let options = {
    theme: "toasted-primary",
    keepOnHover: true,
    position: "bottom-right",
    duration: 8000,
    containerClass: 'toast',
    iconPack: 'mdi',
    icon: 'information',
    action: {
        text: 'X',
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    }
};

Vue.use(Toasted, options)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import VueQrcode from '@chenfengyuan/vue-qrcode';
import Clipboard from 'v-clipboard'
Vue.component(VueQrcode.name, VueQrcode);

Vue.use(Clipboard)
const ability = new Ability([]);
Vue.use(abilitiesPlugin, ability);
Vue.prototype.ability = ability
Vue.component('can', Can);
new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')

Vue.use(VModal, {
        dynamic: true,
        injectModalsContainer: true,
        componentName: 'Draggable',
        dynamicDefaults : {
            draggable: true,
            resizable: true,
            scrollable : true,
            adaptive: true,
            cancelable: true,
            height : 'auto',
        }
    }
);
<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-toolbar class="no-left-padding" dense flat>
        <v-icon class="mr-2">{{ $icons[relation_name] }}</v-icon>
        <v-toolbar-title style="font-size: 13pt" class="ma-0 pa-0">
          {{ $store.getters.translate(relation_name) }}
          <small class="ml-1 text--lighten-3">
            ({{ records.length }})
          </small>
        </v-toolbar-title>
        <v-spacer/>
        <!--EXCEL EXPORT-->
        <download-excel v-if="records.length > 0" class="v-btn v-btn--text theme--light v-size--small" :fields="export_fields" :data="records" :name="relation_name + '.xls'">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon small class="ml-2" color="primary">mdi-file-excel</v-icon>
        </download-excel>
        <v-divider class="mr-2 ml-2" vertical/>
        <!--LINK-->
        <template v-if="base_table_includable_options.link && selected.length == 0 && canEditBaseModel">
          <base-link
              :label="$store.getters.translate('link') + ' ' + $store.getters.translate(relation_name)"
              @refresh="$emit('change', true)"
              @change="linkOrCreateRecord"
              :module_name="relation_name"
              :excluded_ids="$lodash.map(records, 'id')"
              :scheme_id="scheme_id"
              :companies="companies"/>
          <v-divider class="mr-2 ml-2" vertical/>
        </template>
        <!--DELETE-->
        <template v-if="selected.length > 0 && canEditBaseModel">
          <v-btn color="red" @click.stop="bulkArchiveOrDetach" text small>
            <span v-if="base_table_includable_options.detachable">{{ $store.getters.translate("detach") }}</span>
            <span v-else>{{ $store.getters.translate("delete") }}</span>
            <v-icon color="red" class="mr-2">mdi-link-variant</v-icon>
            {{ selected.length }}
          </v-btn>
          <v-divider class="mr-2 ml-2" vertical/>
        </template>
        <!--CREATE-->
        <template v-if="base_table_includable_options.create && canCreate">
          <v-btn @click.stop="$emit('create', true)" text small color="primary">
            {{ $store.getters.translate("create") }}
            <v-icon small class="ml-2">mdi-plus</v-icon>
          </v-btn>
          <v-divider class="mr-2 ml-2" vertical/>
        </template>
      </v-toolbar>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        :headers="headers"
        :items="records"
        :loading="loading"
        class="elevation-1"
        item-key="id"
        selectable-key="id"
        :show-select="!base_table_includable_options.no_select"
        v-model="selected"
        :options.sync="options"
        :footer-props="footer_props"
        :custom-sort="customSort"
        :search="search">
        <template v-slot:top>
          <v-text-field
              v-model="search"
              @click:clear="clearSearch"
              :label="$store.getters.translate('search')"
              clearable
              outlined
              dense
              append-icon="mdi-magnify"/>
        </template>
        <!--CUSTOM DRAGGABLE TABLE FOR FORM QUESTIONS AND QUESTION CATEGORIES MODELS ONLY-->
        <template v-if="relation_name === 'formquestions' || relation_name === 'questioncategories' || relation_name === 'schemesessions'" v-slot:body="props">
          <draggable :list="props.items" tag="tbody" @end="endDrag(props.items)">
            <tr v-for="(item, index) in props.items" :key="index">
              <td>
                <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
              </td>
              <td>{{ item.sort_order }}</td>
              <td><a @click="$emit('edit', item)">{{ $store.getters.translate(item.name) }}</a></td>
              <td v-if="relation_name === 'formquestions'">{{ $store.getters.translate(item.category_name) }}</td>
              <td v-else-if="relation_name === 'schemesessions' && scheme_owner && scheme_owner.toLowerCase() === 'stipel'">{{ $store.getters.translate(item.stipel_name) }}</td>
              <td>{{ moment(item.updated_at, "YYYY-MM-DD").format("DD-MM-YYYY") }}</td>
              <!--ACTION BUTTONS-->
              <td style="text-align: right">
                <v-icon @click="$emit('edit', item)" small class="mr-2">mdi-pencil</v-icon>
                <v-icon v-if="relation_name !== 'schemesessions'" @click="deleteRecord(item.id)" small color="error">mdi-trash-can</v-icon>
              </td>
            </tr>
          </draggable>
        </template>
        <template v-else v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td v-if="!base_table_includable_options.no_select">
              <v-checkbox v-model="selected" :value="item" style="margin-top: 4px; height: 32px"/>
            </td>
            <td v-for="(key, index) in Object.keys(response_fields)" :key="index">
              <!--CHECKBOXES/BOOLEAN VALUES-->
              <template v-if="response_fields[key] === 'boolean'">
                <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                <template v-else>0</template>
              </template>
              <!--DATES-->
              <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
              </template>
              <!--DROPDOWNS-->
              <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                <template v-if="Array.isArray(item[key])">
                  <template v-if="item[key].length > 1">
                    <v-chip style="height: auto;" class="mr-2" x-small v-for="elem in item[key]" :key="elem">
                      {{ elem }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                  </template>
                </template>
                <template v-else>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                </template>
              </template>
              <!--LINKS-->
              <template v-else-if="response_fields[key] === 'link'">
                <a @click="$emit('edit', item)">
                  <v-avatar v-if="key === 'name' && item.person" class="mr-2" size="20">
                    <v-img style="border: 1px" :src="item.person.avatar ? item.person.avatar : '../../../assets/images/no-user.jpg'"/>
                  </v-avatar>
                  {{ $lodash.truncate(item[key], { length: 50 }) }}
                </a>
              </template>
              <!--ID FIELDS-->
              <template v-else-if="key === 'id'">
                <a @click="$emit('edit', item)">{{ item[key] }}</a>
              </template>
              <!--TEXT FIELDS-->
              <template v-else>
                {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
              </template>
            </td>
            <!--ACTION BUTTONS-->
            <td style="text-align: right">
              <template v-if="relation_name === 'notes'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click.prevent="setShowInPortal(item)" v-bind="attrs" v-on="on" small text :loading="loading">
                      <v-icon small :color="item.visible_candidate ? 'primary' : 'secondary'" v-text="item.visible_candidate ? 'mdi-eye' : 'mdi-eye-off'"/>
                    </v-btn>
                  </template>
                  <span>{{ $store.getters.translate("visible_candidate") }}</span>
                </v-tooltip>
              </template>
              <v-icon v-if="canEdit" @click="$emit('edit', item)" color="secondary" small class="mr-2">mdi-pencil</v-icon>
            </td>
          </tr>
          <tr v-if="records.length === 0">
            <td class="text-center grey--text" :colspan="headers.length + 1">
              <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
              <span v-else>{{ $store.getters.translate("loading") }}</span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import BaseLink from "./BaseLink";
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions.js";
import Draggable from "vuedraggable";

export default {
  components: { Draggable, BaseLink },
  props: ["page", "parent_module_id", "parent_module_related_id", "relation_name", "scheme_id"],
  data() {
    return {
      loading: false,
      headers: [],
      selected: [],
      records: [],
      companies: null,
      search: "",
      options: {},
      footer_props: {
        itemsPerPageOptions: [15, 25, 50, 75, 100, 125, 150, 175, 200],
        rowsPerPageText: "Rows per page:",
      },
      base_table_includable_options: {},
      response_fields: {},
      export_fields: {},
      scheme_owner: null
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.relation_name + "_includable_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.relation_name + "_includable_options");
    }
    this.base_table_includable_options = helpFunctions.base_table_includable_options[this.page.name][this.relation_name];
    if(this.relation_name !== 'schemesessions' && this.relation_name !== 'formquestions' && this.relation_name !== 'questioncategories') {
      this.getFields(this.$models[this.relation_name]);
    }
  },
  methods: {
    endDrag(items) {
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        for (let z = 0; z < items.length; z++) {
          if (temp_records[i].id === items[z].id && temp_records[i].sort_order !== z + 1) {
            this.records[i].sort_order = z + 1;
            this.fieldDataChanged(this.records[i]);
          }
        }
      }
      this.$toasted.success(this.$store.getters.translate("successfully_saved"));
    },
    fieldDataChanged(field) {
      this.loading = true;
      this.$http
          .patch(this.$store.getters.appUrl + "v2/" + this.relation_name + "/" + field.id, field)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.loading = true;
          return this.$http
              .delete(this.$store.getters.appUrl + "v2/" + this.relation_name + "/" + id)
              .then(() => {
                this.loading = false;
                this.$emit("change");
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      });
    },
    bulkArchiveOrDetach() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          var result = {
            model_id: this.parent_module_id,
            relation_name: this.$relations[this.relation_name] ? this.$relations[this.relation_name] : this.relation_name,
            ids: this.$lodash.map(this.selected, "id"),
          };
          if(this.page.model === 'employee' && (this.relation_name === 'roles' || this.relation_name === 'tokens')) {
            result.model = 'User';
          }
          else {
            result.model = this.page.model;
          }
          if(this.parent_module_related_id) {
            result.model_related_id = this.parent_module_related_id;
          }
          this.$http
            .post(this.$store.getters.appUrl + "v2/bulk/remove", result)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.selected = [];
              this.$emit("change");
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    linkOrCreateRecord(objects) {
      this.loading = true;
      var result = {
        attach: this.$lodash.map(objects, "id"),
        model_id: this.parent_module_id,
        relation_name: this.$relations[this.relation_name] ? this.$relations[this.relation_name] : this.relation_name,
        objects: objects,
      };
      if(this.page.model === 'employee' && (this.relation_name === 'roles' || this.relation_name === 'tokens')) {
        result.model = 'User';
      }
      else {
        result.model = this.page.model;
      }
      if(this.parent_module_related_id) {
        result.model_related_id = this.parent_module_related_id;
      }
      this.$http
        .post(this.$store.getters.appUrl + "v2/bulk/attach", result)
        .then((response) => {
          this.loading = false;
          if(response.data === 'created' || response.data === 'attached') {
            this.$toasted.success(this.$store.getters.translate("success"));
            this.$emit("change");
          }
          else {
            this.$toasted.error(this.$store.getters.translate(response.data));
          }
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    clearSearch() {
      this.search = "";
    },
    canCreate() {
      return this.$can("create", this.$models[this.relation_name]);
    },
    canEdit() {
      return this.$can("edit", this.$models[this.relation_name]);
    },
    canEditBaseModel() {
      return this.$can("edit", this.page.model);
    },
    setShowInPortal(note) {
      note.visible_candidate = !note.visible_candidate;
      this.$http
        .patch(this.$store.getters.appUrl + "v2/notes/" + note.id, note)
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("success"));
          this.$emit("change");
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    customSort(items, index, isDesc) {
      let field = index[0];
      let desc = isDesc[0];
      let result = this.$lodash.sortBy(items, field);
      if (field === "date_of_birth") {
        result = result.sort((a, b) => {
          a = moment(a["date_of_birth"], "DD-MM-YYYY").toDate();
          b = moment(b["date_of_birth"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (field === "examination_date") {
        result = result.sort((a, b) => {
          a = moment(a["examination_date"], "DD-MM-YYYY").toDate();
          b = moment(b["examination_date"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (desc) {
        return result.reverse();
      }
      return result;
    },
    async getFields(module_name) {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_in_search=true&model_type=" + module_name)
          .then((response) => {
            this.headers = [];
            response.data.data.forEach((item) => {
              this.headers.push({
                text: this.$store.getters.translate(item.name),
                align: "start",
                sortable: true,
                value: item.name,
              });
              this.response_fields[item.name] = item.field_type;
              this.export_fields[this.$store.getters.translate(item.name)] = item.name;
            });
            this.headers.push({
              text: this.$store.getters.translate("actions"),
              sortable: false,
              align: "right",
              value: "actions",
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
    updateHeaders(type, record) {
      if(type === 'scheme_session') {
        this.scheme_owner = record.scheme_owner;
        if(record.scheme_owner && record.scheme_owner.toLowerCase() === 'stipel') {
          this.headers = helpFunctions.stipel_schemesession_headers;
        }
        else {
          this.headers = helpFunctions.schemesession_headers;
        }
      }
      else if(type === 'form_question') {
        this.headers = helpFunctions.formquestion_headers;
      }
      else if(type === 'question_category') {
        this.headers = helpFunctions.questioncategory_headers;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.selected = [];
        helpFunctions.setLocalStorageValue(this.relation_name + "_includable_options", this.options);
      },
      deep: true,
    },
  },
};
</script>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDraggableModal',{ref:_vm.modal_name,attrs:{"name":_vm.modal_name,"pdfSource":_vm.pdfSource,"max-height":"80vh","width":"70vw","draggable":"false","margin":"auto"}},[_c(VCard,{attrs:{"height":"100%"}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.reset.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$store.getters.translate('toolbar')))]),_c(VSpacer)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"preview-container",staticStyle:{"margin":"0 auto"}},[_c('div',[_c('object',{attrs:{"data":_vm.pdfSource + '#zoom=100',"type":"application/pdf","width":"100%","height":"800vh"}})])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
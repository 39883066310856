<template>
  <div>
    <v-divider/>
    <v-footer flat>
      <!-- IF THIS IS A NOTE -->
      <template v-if="record.is_request">
        <v-tooltip v-if="!record.request_fulfilled" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-3" x-small color="red">mdi-checkbox-blank-circle</v-icon>
          </template>
          <span>{{ $store.getters.translate("is_request") }}</span>
        </v-tooltip>
        <v-tooltip v-else top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-3" x-small color="green">mdi-checkbox-blank-circle</v-icon>
          </template>
          <span>{{ $store.getters.translate("request_fulfilled") }}</span>
        </v-tooltip>
      </template>
      <!-- OTHER MODELS -->
      <template v-else>
        <v-tooltip top v-if="status_check">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-3" x-small color="red">mdi-checkbox-blank-circle</v-icon>
          </template>
          <span>{{ $store.getters.translate("open_requests") }} {{ status_check }}</span>
        </v-tooltip>
        <v-tooltip v-else top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-3" x-small color="green">mdi-checkbox-blank-circle</v-icon>
          </template>
          <span>{{ $store.getters.translate("no_open_requests") }}</span>
        </v-tooltip>
      </template>
      <v-chip v-if="status" label small outlined>
        {{ $store.getters.translate(status) }}
      </v-chip>
      <v-spacer/>
      <v-chip v-if="record.creator" label small outlined>
        <v-icon x-small left>mdi-plus</v-icon>
        {{ record.creator }}
      </v-chip>
      <v-chip class="ml-2" v-if="record.editor" label small outlined>
        <v-icon x-small left>mdi-pencil</v-icon>
        {{ record.editor }}
      </v-chip>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "BaseFooter",
  props: ["model"],
  data() {
    return {
      record: null,
      status_check: null,
      status: null,
    };
  },
  methods: {
    load() {
      if (this.model && this.model !== "notes" && this.record && this.record.id) {
        this.check();
      }
    },
    check() {
      let payload = {
        model: this.$models[this.model],
        model_id: this.record.id,
      };
      this.$http
        .post(this.$store.getters.appUrl + "v2/status", payload)
        .then((response) => {
          this.status_check = response.data.status_check;
          this.status = response.data.status;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.label-custom {
  border: 0px !important;
  font-style: italic;
}
</style>
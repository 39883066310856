<template>
  <div>
    <v-btn small @click="$refs.modal_notification.open();showHandler();">
      {{ $store.getters.translate("messages") }}
    </v-btn>
    <v-divider vertical/>
    <BaseDraggableModal
        :name="'modal_notification'"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        ref="modal_notification">
      <v-card>
        <BaseModal>
          <v-toolbar dark color="primary" class="draggable_selector" slot="header" dense flat>
            <v-btn icon @click.stop="$refs.modal_notification.close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate("messages") }}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
          <v-card-text slot="content">
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="notifications"
                  :loading="loading_notifications"
                  :items-per-page="5">
                  <template v-slot:item.data="{ item }">
                    <div class="mt-2 mb-2">
                      <p v-html="item.data.message"></p>
                      <v-chip x-small label>
                        <v-icon left x-small>mdi-eye</v-icon>
                        <span>{{ item.read_at ? moment(item.read_at).format("DD-MM-YYYY") : $store.getters.translate("has_not_been_read") }}</span>
                      </v-chip>
                      <v-chip class="ml-2" x-small label>
                        <v-icon left x-small>mdi-plus</v-icon>
                        <span>{{ item.created_at ? moment(item.created_at).format("DD-MM-YYYY") : "-" }}</span>
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon @click="deleteNotification(item.id)" right>
                      mdi-close
                    </v-icon>
                  </template>
                </v-data-table>
                <template v-if="$store.getters.sms && phonenumber">
                  <v-switch v-model="record.prefers_sms" :label="$store.getters.translate('send_with_sms')"/>
                  <small v-if="record.prefers_sms">{{ $store.getters.translate("sms_cost_hint") }}</small>
                </template>
                <v-textarea
                  v-if="record.prefers_sms"
                  outlined
                  v-model="record.message"
                  :label="$store.getters.translate('send_notification')"/>
                <base-message
                  v-else
                  v-model="record.message"
                  :label="$store.getters.translate('send_notification')"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions slot="footer">
            <v-btn
              :loading="saving_template"
              :disabled="!record.message"
              text
              @click="save">
              {{ $store.getters.translate("save_template") }}
            </v-btn>
            <v-spacer/>
            <v-btn
              :loading="loading"
              :disabled="isDisabled"
              color="primary"
              text
              @click="sendNotification">
              {{ $store.getters.translate("send") }}
            </v-btn>
          </v-card-actions>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["user_id", "recipient", "phonenumber"],
  data() {
    return {
      loading: false,
      saving_template: false,
      record: {
        to: null,
        cc: null,
        message: null,
        subject: null,
        prefers_sms: false,
      },
      notifications: [],
      loading_notifications: false,
      headers: [
        {
          text: this.$store.getters.translate("message"),
          align: "start",
          sortable: true,
          value: "data",
          width: "90%",
        },
        {
          text: this.$store.getters.translate("actions"),
          sortable: false,
          align: "right",
          value: "actions",
          width: "10%",
        },
      ],
    };
  },
  methods: {
    sendNotification() {
      this.loading = true;
      this.record.user_id = this.user_id;
      this.$http
        .post(this.$store.getters.appUrl + "v2/notifications", this.record)
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("success"));
          this.notifications.unshift({
            data: {
              message: this.record.message,
            },
            read_at: null,
          });
          this.record = {
            to: null,
            cc: null,
            message: null,
            subject: null,
            prefers_sms: this.record.prefers_sms,
          };
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    save() {
      this.saving_template = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/messagetemplates", this.record)
        .then(() => {
          this.saving_template = false;
          this.$toasted.success(this.$store.getters.translate("successfully_saved"));
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.saving_template = false;
        });
    },
    getNotifications() {
      this.loading_notifications = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/get-notifications/" + this.user_id)
        .then((response) => {
          this.notifications = response.data;
          this.loading_notifications = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading_notifications = false;
        });
    },
    deleteNotification(id) {
      this.loading = true;
      this.$http
        .delete(this.$store.getters.appUrl + "v2/notifications/" + id)
        .then(() => {
          this.$toasted.success(this.$store.getters.translate("deleted"));
          this.loading = false;
          this.getNotifications();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    showHandler() {
      this.record = {
        message: "",
      };
      this.getNotifications();
      if (this.recipient) {
        this.to = this.recipient;
      }
    }
  },
  computed: {
    isDisabled() {
      return !this.record.message;
    },
  },
};
</script>
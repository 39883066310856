import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider),_c(VFooter,{attrs:{"flat":""}},[(_vm.record.is_request)?[(!_vm.record.request_fulfilled)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"x-small":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-blank-circle")])]}}],null,false,1948153132)},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("is_request")))])]):_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"x-small":"","color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-blank-circle")])]}}],null,false,484815844)},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("request_fulfilled")))])])]:[(_vm.status_check)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"x-small":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-blank-circle")])]}}],null,false,1948153132)},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("open_requests"))+" "+_vm._s(_vm.status_check))])]):_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"x-small":"","color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-checkbox-blank-circle")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate("no_open_requests")))])])],(_vm.status)?_c(VChip,{attrs:{"label":"","small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate(_vm.status))+" ")]):_vm._e(),_c(VSpacer),(_vm.record.creator)?_c(VChip,{attrs:{"label":"","small":"","outlined":""}},[_c(VIcon,{attrs:{"x-small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.record.creator)+" ")],1):_vm._e(),(_vm.record.editor)?_c(VChip,{staticClass:"ml-2",attrs:{"label":"","small":"","outlined":""}},[_c(VIcon,{attrs:{"x-small":"","left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.record.editor)+" ")],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
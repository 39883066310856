<template>
  <div>
    <slot name="button"/>
    <BaseDraggableModal :name="'modal_documentation'" :fullscreen="true" ref="modal_documentation">
      <v-card :loading="loading" height="100%">
        <BaseModal>
          <v-toolbar slot="header" class="draggable_selector" dark color="primary" dense flat>
            <v-btn icon @click.stop="$refs.modal_documentation.close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate(name) }}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text style="height: 96vh" class="text-center">
              <v-row style="height: 100%">
                <iframe
                  frameborder="0"
                  style="overflow: hidden;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    height: 100%;
                    width: 100%;
                    position: relative;
                    top: 15px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;"
                  height="100%"
                  width="100%"
                  :src="src"/>
              </v-row>
            </v-card-text>
          </div>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </div>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["name", "src"],
  data() {
    return {
      loading: true,
      blob: null,
      message: this.$store.getters.translate("loading"),
      not_found: false,
    };
  },
  methods: {},
};
</script>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("button"),_c('BaseDraggableModal',{ref:"modal_documentation",attrs:{"name":'modal_documentation',"fullscreen":true}},[_c(VCard,{attrs:{"loading":_vm.loading,"height":"100%"}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.modal_documentation.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$store.getters.translate(_vm.name)))]),_c(VSpacer)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),_c(VCardText,{staticClass:"text-center",staticStyle:{"height":"96vh"}},[_c(VRow,{staticStyle:{"height":"100%"}},[_c('iframe',{staticStyle:{"overflow":"hidden","overflow-x":"hidden","overflow-y":"hidden","height":"100%","width":"100%","position":"relative","top":"15px","left":"0px","right":"0px","bottom":"0px"},attrs:{"frameborder":"0","height":"100%","width":"100%","src":_vm.src}})])],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
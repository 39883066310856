<template>
  <v-card class="pa-5">
    <v-btn v-if="current_view === 'day'" @click.stop="returnBack" small color="primary" class="ma-1" style="float: left;width:200px;">
      {{ $store.getters.translate("back_to_calendar") }}
      <v-icon right x-small>mdi-exit-to-app</v-icon>
    </v-btn>
    <div v-if="current_view === 'day'" style="float: right;">
      <v-btn @click.stop="exportCalendar('pdf')" small color="primary" class="ma-1" style="width:200px;">
        {{ $store.getters.translate("export_to_pdf") }}
        <v-icon right x-small>mdi-download</v-icon>
      </v-btn>
      <br/>
      <v-btn @click.stop="exportCalendar('excel')" small color="primary" class="ma-1" style="width:200px;">
        {{ $store.getters.translate("export_to_excel") }}
        <v-icon right x-small>mdi-download</v-icon>
      </v-btn>
    </div>
    <FullCalendar style="margin-top: 75px;" ref="full_calendar" :plugins="plugins" :options="calendarOptions" />
  </v-card>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions";
import Tooltip from "tooltip.js";

export default {
  components: {
    FullCalendar
  },
  props: ["assessor_name", "exams"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("planner"),
        name: "planner",
        model: "planner",
      },
      calendarOptions: {
        schedulerLicenseKey:'0428499722-fcs-1583088865',
        plugins: [ dayGridPlugin, interactionPlugin, resourceTimeGridPlugin ],
        headerToolbar: {
          start: 'title',
          center: '',
          end: 'today prev,next'
        },
        initialView: 'dayGridMonth',
        allDaySlot: false,
        datesAboveResources: true,
        eventTextColor: "white",
        slotDuration: "00:05:00",
        slotMinTime: "08:00:00",
        slotMaxTime: "18:00:00",
        editable: false,
        eventOverlap: false,
        eventResizableFromStart: false,
        weekends: false,
        dateClick: this.handleDateClick,
        resources: [],
        events: [],
        eventDidMount: function(info) {
          if(info.event._def.extendedProps.description) {
            new Tooltip(info.el, {
              title: info.event._def.extendedProps.description,
              placement: 'top',
              trigger: 'hover',
              container: 'body'
            });
          }
        },
      },
      current_view: "month",
      selected_date: null,
      selected_exams: [],
    }
  },
  mounted() {
    this.fillMonthCalendar();
  },
  methods: {
    handleDateClick(info) {
      if(this.current_view === "month") {
        this.showInDayCalendar(info.date);
      }
    },
    showInDayCalendar(date) {
      this.selected_exams = [];
      this.selected_date = moment(date).format('DD-MM-YYYY');
      this.exams.forEach((exam) => {
        if(exam.examination_date === this.selected_date) {
          this.selected_exams.push(exam);
        }
      });
      if(this.selected_exams.length === 0) {
        this.$toasted.error(this.$store.getters.translate("no_exams_on_selected_day"));
      }
      else {
        this.current_view = "day";
        this.$refs.full_calendar.getApi().changeView('resourceTimeGridDay');
        this.$refs.full_calendar.getApi().setOption("eventTextColor", "black");
        this.$refs.full_calendar.getApi().setOption("editable", false);
        this.$refs.full_calendar.getApi().setOption("eventResizableFromStart", false);
        this.$refs.full_calendar.getApi().setOption("headerToolbar", {start: '', center: 'title', end: ''});
        this.$refs.full_calendar.getApi().gotoDate(date);
        this.clearCalendar();
        this.loadSessions();
      }
    },
    returnBack() {
      this.current_view = "month";
      this.$refs.full_calendar.getApi().changeView('dayGridMonth');
      this.$refs.full_calendar.getApi().setOption("eventTextColor", "white");
      this.$refs.full_calendar.getApi().setOption("editable", false);
      this.$refs.full_calendar.getApi().setOption("eventResizableFromStart", false);
      this.$refs.full_calendar.getApi().setOption("headerToolbar", {start: 'title', center: '', end: 'today prev,next'});
      this.clearCalendar();
      this.fillMonthCalendar();
    },
    fillMonthCalendar() {
      this.exams.forEach((exam) => {
        if(exam.examination_date) {
          var color = "blue";
          if (moment(exam.examination_date, "DD-MM-YYYY") < moment.now()) {
            color = "green";
          } else if (exam.re_examination) {
            color = "darkblue";
          }
          this.$refs.full_calendar.getApi().addEvent({
            "id": exam.id,
            "allDay": true,
            "start": moment(exam.examination_date, "DD-MM-YYYY").toDate(),
            "end": moment(exam.examination_date, "DD-MM-YYYY").toDate(),
            "title": exam.name,
            "color": color
          });
        }
      });
    },
    async loadSessions() {
      this.selected_exams.forEach((exam) => {
        let sessions = exam.sessions;
        var added_candidates = [];
        sessions.forEach((session) => {
          let title = helpFunctions.getTitle(session.name);
          let color = helpFunctions.getColor(title);
          title += ' (' + exam.name + ')';
          let description = "Examen: " + exam.name + "\n" + "Lokatie: " + exam.location.name;
          if(exam.room) {
            description += "\n" + "Ruimte: " + exam.room.name;
          }
          session.exam_candidate_sessions.forEach((exam_candidate) => {
            if (!added_candidates.includes(exam_candidate.candidate.id)) {
              added_candidates.push(exam_candidate.candidate.id);
              this.$refs.full_calendar.getApi().addResource({"id": exam_candidate.candidate.id, "title": exam_candidate.candidate.name});
            }
            if(exam_candidate.from && exam_candidate.till) {
              this.$refs.full_calendar.getApi().addEvent(helpFunctions.getPlannerEvent(exam_candidate.id, exam_candidate.candidate.id, exam.examination_date, exam_candidate.from, exam_candidate.till, title, color, description));
            }
          });
        });
      });
    },
    clearCalendar() {
      let removeEvents = this.$refs.full_calendar.getApi().getEvents();
      removeEvents.forEach(event => {
        event.remove();
      });
      this.$refs.full_calendar.getApi().refetchResources();
    },
    exportCalendar(export_type) {
      let html = helpFunctions.exportCalendar(export_type, this.$refs.full_calendar.getApi().getResources());
      if(export_type === 'excel') {
        this.exportTableToExcel(html);
      }
      else {
        this.exportTableToPdf(html);
      }
    },
    exportTableToPdf(html) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-pdf", {html: html, file_name: this.assessor_name + " (" + this.selected_date + ")"}, {responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.assessor_name + " (" + this.selected_date + ").pdf";
            link.click();
            URL.revokeObjectURL(link.href);
          });
    },
    exportTableToExcel(html) {
      const link = document.createElement("a");
      link.href = 'data: application/vnd.ms-excel, ' + html.replace(/ /g, '%20');
      link.download = this.assessor_name + " (" + this.selected_date + ").xls";
      link.click();
    },
  },
  watch: {
    exams: {
      handler() {
        this.clearCalendar();
        this.fillMonthCalendar();
      },
    },
  },
}
</script>

<style>
.fc-day-sun, .fc-day-sat {
  background-color: #f2f2f2;
}
.tooltip-inner {
  white-space: pre-wrap;
}
.popper, .tooltip {
  position: absolute;
  z-index: 9999;
  background: #FFC107;
  color: black;
  width: 250px;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0,0,0,0.5);
  padding: 10px;
  text-align: center;
}
.style5 .tooltip {
  background: #1E252B;
  color: #FFFFFF;
  max-width: 250px;
  width: auto;
  font-size: .8rem;
  padding: .5em 1em;
}
.popper .popper__arrow,
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}
.tooltip .tooltip-arrow,
.popper .popper__arrow {
  border-color: #FFC107;
}
.style5 .tooltip .tooltip-arrow {
  border-color: #1E252B;
}
.popper[x-placement^="top"],
.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}
.popper[x-placement^="top"] .popper__arrow,
.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.popper[x-placement^="bottom"],
.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow,
.popper[x-placement^="bottom"] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^="right"],
.popper[x-placement^="right"] {
  margin-left: 5px;
}
.popper[x-placement^="right"] .popper__arrow,
.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.popper[x-placement^="left"],
.tooltip[x-placement^="left"] {
  margin-right: 5px;
}
.popper[x-placement^="left"] .popper__arrow,
.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
</style>
<template>
  <v-row>
    <v-col>
      <v-form v-model="valid">
        <template v-for="(row, index) in updated_emailaddresses">
          <base-row
              v-if="row.type === email_type"
              :key="index"
              :label="$store.getters.translate(label)"
              :value="row.emailaddress"
              :deletable="true"
              @delete="deleteEmailaddress(row)"/>
        </template>
        <v-text-field
          v-model="new_emailaddress.emailaddress"
          validate-on-blur
          @click:append-outer="add"
          append-outer-icon="mdi-content-save"
          :rules="emailRules"
          required
          :placeholder="$store.getters.translate(label)"
          v-on:keydown.enter.prevent="add"
          @blur="add"/>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["value","is_submit", "email_type", "label"],
  data() {
    return {
      updated_emailaddresses: [],
      new_emailaddress: {},
      valid: true,
      emailRules: [
        (v) => !!v || this.$store.getters.translate("please_submit_valid_email"),
        (v) => /.+@.+\..+/.test(v) || this.$store.getters.translate("please_submit_valid_email"),
        (v) => this.check(v) || this.$store.getters.translate("please_submit_valid_email"),
      ],
    };
  },
  mounted() {
    this.new_emailaddress = {
      emailaddress: "",
      active: 0,
      type: this.email_type
    };
    this.updated_emailaddresses = this.value ? this.value : [];
  },
  methods: {
    add() {
      if (this.valid && this.new_emailaddress.emailaddress !== "") {
        this.updated_emailaddresses.push(this.new_emailaddress);
        this.new_emailaddress = {
          emailaddress: "",
          active: 0,
          type: this.email_type
        };
        this.$emit("input", this.updated_emailaddresses);
        this.$emit("change");
      }
    },
    check(value) {
      let validator = require("email-validator");
      return validator.validate(value);
    },
    deleteEmailaddress(row) {
      this.updated_emailaddresses = this.updated_emailaddresses.filter((record) => record.emailaddress !== row.emailaddress);
      if (this.updated_emailaddresses.length == 0) {
        this.$emit('email_deleted');
      }
      this.$emit("input", this.updated_emailaddresses);
      this.$emit("change");
      if (row.id) {
          this.$http
            .delete(this.$store.getters.appUrl + "v2/emailaddresses/" + row.id)
            .then(() => {
              this.$toasted.success(this.$store.getters.translate("success"));
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
            });
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.updated_emailaddresses = this.value;
      },
    },
    is_submit:function() {
        this.new_emailaddress.emailaddress = '';
    }
  },
};
</script>
<template>
  <v-card class="pa-5" :loading="loading" :disabled="loading">
    <div id="unscheduled_events_block" style="display: none;">
      <v-btn v-if="current_view === 'day'" @click.stop="returnBack" small color="primary" class="ma-1" style="float: left;width:200px;">
        {{ $store.getters.translate("back_to_calendar") }}
        <v-icon right x-small>mdi-exit-to-app</v-icon>
      </v-btn>
      <div style="float: right;">
        <v-btn @click.stop="exportCalendar('pdf')" small color="primary" class="ma-1" style="width:200px;">
          {{ $store.getters.translate("export_to_pdf") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
        <br/>
        <v-btn @click.stop="exportCalendar('excel')" small color="primary" class="ma-1" style="width:200px;">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon right x-small>mdi-download</v-icon>
        </v-btn>
      </div>
      <h2 class="text-center">{{ $store.getters.translate("unscheduled_events") }}</h2>
      <table class="ma-5 text-center">
        <thead><tr id="unscheduled_events_head" style="vertical-align: top;"/></thead>
        <tbody><tr id="unscheduled_events_body" style="vertical-align: top;"/></tbody>
      </table>
    </div>
    <FullCalendar ref="full_calendar" :plugins="plugins" :options="calendarOptions" />
  </v-card>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    FullCalendar
  },
  props: ["loading", "room_name", "exams"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("planner"),
        name: "planner",
        model: "planner",
      },
      calendarOptions: {
        schedulerLicenseKey:'0428499722-fcs-1583088865',
        plugins: [ dayGridPlugin, interactionPlugin, resourceTimeGridPlugin ],
        headerToolbar: {
          start: 'title',
          center: '',
          end: 'today prev,next'
        },
        initialView: 'dayGridMonth',
        allDaySlot: false,
        datesAboveResources: true,
        eventTextColor: "white",
        slotDuration: "00:05:00",
        slotMinTime: "08:00:00",
        slotMaxTime: "18:00:00",
        editable: false,
        eventOverlap: false,
        eventResizableFromStart: false,
        weekends: false,
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventResize: this.handleEventDropResize,
        eventDrop: this.handleEventDropResize,
        drop: this.handleExternalEventDrop,
        eventReceive: this.handleEventReceive,
        resources: [],
        events: [],
      },
      should_be_removed: false,
      current_view: "month",
      selected_date: null,
      selected_exams: [],
    }
  },
  methods: {
    handleDateClick(info) {
      if(this.current_view === "month") {
        this.showInDayCalendar(info.date);
      }
    },
    handleEventClick(info) {
      if(this.current_view === "month") {
        this.showInDayCalendar(info.event._instance.range.start);
      }
      else {
        this.$confirm(this.$store.getters.translate("delete_schedule")).then((res) => {
          if (res) {
            this.sendRequest(info.event._def.publicId, null, null);
            document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).innerHTML = info.event._def.title;
            document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).style.backgroundColor = helpFunctions.getColor(info.event._def.title);
            info.event.remove();
          }
        });
      }
    },
    showInDayCalendar(date) {
      this.selected_exams = [];
      this.selected_date = moment(date).format('DD-MM-YYYY');
      this.exams.forEach((exam) => {
        if(exam.examination_date === this.selected_date) {
          this.selected_exams.push(exam);
        }
      });
      if(this.selected_exams.length === 0) {
        this.$toasted.error(this.$store.getters.translate("no_exams_on_selected_day"));
      }
      else {
        this.current_view = "day";
        this.$refs.full_calendar.getApi().changeView('resourceTimeGridDay');
        this.$refs.full_calendar.getApi().setOption("eventTextColor", "black");
        this.$refs.full_calendar.getApi().setOption("editable", true);
        this.$refs.full_calendar.getApi().setOption("eventResizableFromStart", true);
        this.$refs.full_calendar.getApi().setOption("headerToolbar", {start: '', center: 'title', end: ''});
        this.$refs.full_calendar.getApi().gotoDate(date);
        document.getElementById("unscheduled_events_block").style.display = 'block';
        this.clearCalendar();
        this.loadSessions();
      }
    },
    handleEventDropResize(info) {
      if(this.current_view === "day") {
        let from = moment.utc(info.event._instance.range.start).format("HH:mm:ss");
        let till = moment.utc(info.event._instance.range.end).format("HH:mm:ss");
        this.sendRequest(info.event._def.publicId, from, till);
      }
    },
    handleExternalEventDrop(info) {
      if(this.current_view === "day") {
        let obj = JSON.parse(info.draggedEl.dataset.event);
        if (obj.resourceId === info.resource._resource.id) {
          this.should_be_removed = false;
        }
        else {
          this.should_be_removed = true;
        }
      }
    },
    handleEventReceive(info) {
      if(this.current_view === "day") {
        if (this.should_be_removed) {
          this.$toasted.error(this.$store.getters.translate("incorrect_candidate"));
          info.event.remove();
          this.should_be_removed = false;
        }
        else {
          document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).innerHTML = '';
          document.getElementById(info.event._def.publicId + '_' + info.event._def.resourceIds[0]).style.backgroundColor = "white";
          let from = moment.utc(info.event._instance.range.start).format("HH:mm:ss");
          let till = moment.utc(info.event._instance.range.end).format("HH:mm:ss");
          this.sendRequest(info.event._def.publicId, from, till);
        }
      }
    },
    sendRequest(id, from, till) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-time", { id: id, from: from, till: till })
          .then(() => {
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    returnBack() {
      this.current_view = "month";
      this.$refs.full_calendar.getApi().changeView('dayGridMonth');
      this.$refs.full_calendar.getApi().setOption("eventTextColor", "white");
      this.$refs.full_calendar.getApi().setOption("editable", false);
      this.$refs.full_calendar.getApi().setOption("eventResizableFromStart", false);
      this.$refs.full_calendar.getApi().setOption("headerToolbar", {start: 'title', center: '', end: 'today prev,next'});
      document.getElementById("unscheduled_events_block").style.display = 'none';
      this.clearCalendar();
      this.fillMonthCalendar();
    },
    fillMonthCalendar() {
      this.exams.forEach((exam) => {
        if(exam.examination_date) {
          var color = "blue";
          if (moment(exam.examination_date, "DD-MM-YYYY") < moment.now()) {
            color = "green";
          } else if (exam.re_examination) {
            color = "darkblue";
          }

          this.$refs.full_calendar.getApi().addEvent({
            "id": exam.id,
            "allDay": true,
            "start": moment(exam.examination_date, "DD-MM-YYYY").toDate(),
            "end": moment(exam.examination_date, "DD-MM-YYYY").toDate(),
            "title": exam.name,
            "color": color
          });
        }
      });
    },
    async loadSessions() {
      document.getElementById("unscheduled_events_head").innerHTML = '';
      document.getElementById("unscheduled_events_body").innerHTML = '';
      this.selected_exams.forEach((exam) => {
        document.getElementById("unscheduled_events_head").innerHTML += '<th class="text-center">' + exam.name + '</th>';
        document.getElementById("unscheduled_events_body").innerHTML += '<td>' +
              '<table style="border-spacing: 0px; -webkit-border-spacing: 0px; border-collapse: collapse; -webkit-border-collapse:collapse;" class="mt-5 mb-5 text-center">' +
                '<thead><tr id="unscheduled_events_head_'+exam.id+'"/></thead>' +
                '<tbody id="unscheduled_events_body_'+exam.id+'"/>' +
              '</table>' +
            '</td>';
          this.$http
              .get(this.$store.getters.appUrl + "v2/exams/" + exam.id + "/sessions")
              .then((response) => {
                let sessions = response.data;
                var added_candidates = [];
                var table_data = [];
                var table_header = "<th style='border:1px solid grey;' class='pa-2'></th>";
                sessions.forEach((session) => {
                  let title = helpFunctions.getTitle(session.name);
                  let color = helpFunctions.getColor(title);
                  title += ' (' + exam.name + ')';
                  let duration = helpFunctions.getDuration(session.scheme_session);
                  session.exam_candidate_sessions.forEach((exam_candidate) => {
                    if (!added_candidates.includes(exam_candidate.candidate.id)) {
                      table_data[exam_candidate.candidate.id] = [];
                      added_candidates.push(exam_candidate.candidate.id);
                      this.$refs.full_calendar.getApi().addResource({"id": exam_candidate.candidate.id, "title": exam_candidate.candidate.name, "sorting": added_candidates.length});
                      table_header += "<th style='border:1px solid grey;' class='pa-2'>" + exam_candidate.candidate.name + "</th>";
                    }
                    if(exam_candidate.status === "exempt") {
                      table_data[exam_candidate.candidate.id][session.name] = '<td style="border:1px solid grey; background-color: gray;" class="pa-2"></td>';
                    }
                    else if(exam_candidate.from && exam_candidate.till) {
                      this.$refs.full_calendar.getApi().addEvent(helpFunctions.getPlannerEvent(exam_candidate.id, exam_candidate.candidate.id, exam.examination_date, exam_candidate.from, exam_candidate.till, title, color));
                      table_data[exam_candidate.candidate.id][session.name] = helpFunctions.getEmptyPlannerTableRow(exam_candidate.id, exam_candidate.candidate.id, duration, title, color);
                    }
                    else {
                      table_data[exam_candidate.candidate.id][session.name] = helpFunctions.getFilledPlannerTableRow(exam_candidate.id, exam_candidate.candidate.id, duration, title, color);
                    }
                  });
                });
                var table_body = "";
                sessions.forEach((session) => {
                  table_body += "<tr>";
                  table_body += "<td style='border:1px solid grey;' class='pa-2'>" + session.name + "</td>";
                  added_candidates.forEach((candidate_id) => {
                    if(table_data[candidate_id][session.name]) {
                      table_body += table_data[candidate_id][session.name];
                    }
                    else {
                      table_body += '<td style="border:1px solid grey; background-color: gray;" class="pa-2"></td>';
                    }
                  });
                  table_body += "</tr>";
                });
                document.getElementById("unscheduled_events_head_"+exam.id).innerHTML = table_header;
                document.getElementById("unscheduled_events_body_"+exam.id).innerHTML = table_body;

                new Draggable(document.getElementById("unscheduled_events_body_"+exam.id), {
                  itemSelector: '.draggable-class'
                });
              })
              .catch((error) => {
                this.$toasted.error(error);
              });
      });
    },
    clearCalendar() {
      let removeEvents = this.$refs.full_calendar.getApi().getEvents();
      removeEvents.forEach(event => {
        event.remove();
      });
      this.$refs.full_calendar.getApi().refetchResources();
    },
    exportCalendar(export_type) {
      this.loading = true;
      let html = helpFunctions.exportCalendar(export_type, this.$refs.full_calendar.getApi().getResources());
      if(export_type === 'excel') {
        this.exportTableToExcel(html);
      }
      else {
        this.exportTableToPdf(html);
      }
    },
    exportTableToPdf(html) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-pdf", {html: html, file_name: this.room_name + " (" + this.selected_date + ")"}, {responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.room_name + " (" + this.selected_date + ").pdf";
            link.click();
            URL.revokeObjectURL(link.href);
            this.loading = false;
          });
    },
    exportTableToExcel(html) {
      const link = document.createElement("a");
      link.href = 'data: application/vnd.ms-excel, ' + html.replace(/ /g, '%20');
      link.download = this.room_name + " (" + this.selected_date + ").xls";
      link.click();
      this.loading = false;
    },
  },
  watch: {
    exams: {
      handler() {
        this.clearCalendar();
        this.fillMonthCalendar();
      },
    },
  },
}
</script>

<style>
.calendar {
  background-color: #FFFFFF;
}
</style>
<template>
  <div class="wrapper">
    <div class="header">
      <slot name="header"/>
    </div>
    <div class="content">
      <slot name="content"/>
      <slot/>
    </div>
    <div class="footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal'
};
</script>

<style scoped>
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: inherit;
    max-height: inherit;
  }

  .wrapper .content{
    flex-shrink: 1;
    overflow: auto;
  }

  .wrapper .header, .wrapper .footer{
    flex-shrink: 0;
  }

  .wrapper .header,  .wrapper .content, .wrapper .footer{
    width: 100%;
  }

  .wrapper .content{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }

  .wrapper .content::-webkit-scrollbar{
    display: none;
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  .wrapper .content::-webkit-scrollbar-thumb {
    background: #FF0000;
  }

  .v--modal-overlay { z-index: 200 !important; }

  @media (max-width: 600px) {
    .wrapper .header,  .wrapper .content, .wrapper .footer{
      margin-right: -25px;
    }
  }
</style>
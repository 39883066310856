<template>
  <div>
    <template>
      <v-text-field
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-if="field.field_type === 'read_only'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          disabled/>
      <v-text-field
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-if="field.field_type === 'link'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="red--text"><strong>*</strong></span></template>
      </v-text-field>
      <v-text-field
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-if="field.field_type === 'text_field' && (field.name === 'phonenumber' || field.name === 'mobilenumber')"
          :label="$store.getters.translate(field.name)"
          placeholder="+31612345678"
          v-model="new_value">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="red--text"><strong>*</strong></span></template>
      </v-text-field>
      <v-text-field
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-else-if="field.field_type === 'text_field'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="red--text"><strong>*</strong></span></template>
      </v-text-field>
      <v-text-field
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-if="field.field_type === 'text_area' && action === 'create'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          rows="7">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="red--text"><strong>*</strong></span></template>
      </v-text-field>
      <base-editor-quill
          class="mb-5 mt-3"
          v-else-if="field.field_type === 'text_area'"
          v-model="new_value"/>
      <v-text-field
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-if="field.field_type === 'date'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          v-mask="'##-##-####'"
          placeholder="dd-mm-yyyy">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="red--text"><strong>*</strong></span></template>
      </v-text-field>
      <v-autocomplete
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-if="field.field_type === 'select_single'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          :items="$lodash.sortBy(field.available_options)">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="red--text"><strong>*</strong></span></template>
      </v-autocomplete>
      <v-combobox
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-if="field.field_type === 'select_multiple'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          :items="$lodash.sortBy(field.available_options)"
          :return-object="false"
          chips
          deletable-chips
          small-chips
          multiple>
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="red--text"><strong>*</strong></span></template>
      </v-combobox>
      <v-switch
          :class="'ma-0 pt-'+$store.getters.lineheight"
          v-if="field.field_type === 'boolean'"
          v-model="new_value"
          :label="$store.getters.translate(field.name)"
          dense/>
    </template>
  </div>
</template>

<script>
import BaseEditorQuill from "../../components/commonComponents/BaseEditorQuill";

export default {
  components: {
    BaseEditorQuill,
  },
  props: ["value", "field", "action"],
  data() {
    return {
      new_value: null,
    };
  },
  mounted() {
    this.new_value = this.value;
  },
  watch: {
    new_value: {
      handler() {
        let data = [this.field.name, this.new_value];
        this.$emit("change", data);
      },
    },
  },
};
</script>
<template>
  <v-form>
    <template v-for="(address, index) in updated_addresses">
      <v-row v-if="address.type === address_type" :key="index">
        <v-col cols="12" sm="4" md="2">
          <div :class="'ma-0 pt-'+$store.getters.lineheight">
            <v-text-field
                style="height: 50px"
                :label="$store.getters.translate('number')"
                v-model="address.number"
                :rules="$rules.required"
                :disabled="disabled"/>
          </div>
        </v-col>
        <v-col cols="12" sm="8" md="10">
          <base-row
              :label="$store.getters.translate(label)"
              :value="address.formatted_address"
              :deletable="!disabled"
              @delete="deleteAddress(address)"
              :prepend="'mdi-google-maps'"
              :hide_copy="true"/>
        </v-col>
      </v-row>
    </template>
    <vuetify-google-autocomplete
        v-if="!loaded && !disabled"
        :id="mapID"
        append-outer-icon="mdi-google-maps"
        classname="form-control"
        v-on:placechanged="addAddress"
        country="nl"
        ref="new_address"
        :placeholder="$store.getters.translate(label)"/>
  </v-form>
</template>

<script>
export default {
  props: ["value", "is_submit", "address_type", "label", "only_one", "disabled", "mapID"],
  data() {
    return {
      updated_addresses: [],
      loaded: false,
    };
  },
  mounted() {
    this.updated_addresses = this.value ? this.value : [];
  },
  methods: {
    addAddress(addressData, placeResultData) {
      var address = {
        street: addressData.route,
        zipcode: addressData.postal_code,
        number: addressData.street_number,
        city: addressData.locality,
        municipality: addressData.administrative_area_level_2,
        latitude: addressData.latitude,
        longitude: addressData.longitude,
        country: "NL",
        formatted_address: placeResultData.formatted_address,
        name: placeResultData.formatted_address,
        type: this.address_type,
      };
      if(!address.zipcode && placeResultData.address_components) {
        placeResultData.address_components.forEach((component) => {
          if (component.types) {
            component.types.forEach((type) => {
              if(type === "postal_code" || type === "postal_code_prefix") {
                address.zipcode = component.long_name;
              }
            });
          }
        });
      }
      if (!this.updated_addresses.find((address) => address.formatted_address === placeResultData.formatted_address)) {
        this.updated_addresses.push(address);
        this.$refs.new_address.clear();
        this.update();
      }
    },
    deleteAddress(old_address) {
      this.updated_addresses = this.updated_addresses.filter((address) => address.id !== old_address.id || address.formatted_address !== old_address.formatted_address);
      if (old_address.id) {
        this.$http
            .delete(this.$store.getters.appUrl + "v2/addresses/" + old_address.id)
            .then(() => {
              this.$toasted.success(this.$store.getters.translate("success"));
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
            });
      }
      this.update();
    },
    update() {
      this.$emit("input", this.updated_addresses);
      if (this.updated_addresses.length > 0) {
        this.$emit("change");
        if (this.only_one) {
          this.loaded = true;
        }
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.updated_addresses = this.value;
      },
    },
    is_submit: function () {
      this.$refs.new_address.clear();
    },
    updated_addresses: {
      handler() {
        if (this.updated_addresses && this.updated_addresses.length === 0) {
          this.loaded = false;
        }
      },
    },
  },
};
</script>